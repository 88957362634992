import React, { Component } from 'react';

class Navbar extends Component {

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-primary pt-4">
        <div className="container">
          <ul className="navbar-nav">
            <li className="nav-item active">
              <a className="nav-link" href="/">
                CVD Tool
              </a>
            </li>
          </ul>
        </div>
      </nav>
    )
  }
}

export default Navbar;
