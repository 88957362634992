import React, { Component } from 'react';
import { Provider } from 'react-redux';

import Figures from 'containers/Figures';
import { store } from 'stores';

class OutputPage extends Component {
  render() {
    const { data, input_data, model_run_id, pdf_exists } = this.props;

    return (
      <Provider store={store}>
        <Figures data={data} input_data={input_data} model_run_id={model_run_id} pdf_exists={pdf_exists} className="outputs" />
      </Provider>
    )
  }
}

export default OutputPage;
