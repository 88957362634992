import React, { Component } from 'react';
import PropTypes from 'prop-types';

const TableHeader = (props) => {
  return (
    <thead>
      <tr>
        <th></th>
        { props.headers.map(header => <th key={`${header}`}>{header}</th>)}
      </tr>
    </thead>
  )

}
export default TableHeader;
