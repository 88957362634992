import React, { Component } from 'react';

import InputHelp from 'components/form/InputHelp';
import { intervention_current_proportion_help, intervention_target_proportion_help } from 'data/form_help_text';

const InterventionProportionHeader = ({ phased, currentText, targetText, currentHelp, targetHelp }) => (
  <div className="form-row proportion-header mb-2 mt-3">
    <div className="col-4"></div>
    <div className="col"><strong>{currentText}</strong><InputHelp text={intervention_current_proportion_help} /></div>
    { phased &&
      <React.Fragment>
        <div className="col"><strong>{`${targetText} Year 1`}</strong><InputHelp text={intervention_target_proportion_help} /></div>
        <div className="col"><strong>{`${targetText} Year 2`}</strong><InputHelp text={intervention_target_proportion_help} /></div>
        <div className="col"><strong>{`${targetText} Year 3`}</strong><InputHelp text={intervention_target_proportion_help} /></div>
      </React.Fragment>
    }
    { !phased &&
      <React.Fragment>
        <div className="col"><strong>{targetText}</strong><InputHelp text={intervention_target_proportion_help} /></div>
      </React.Fragment>
    }
  </div>
)

export default InterventionProportionHeader;
