// Step 2a in the CVD Tool form
import React, { Component } from 'react';
import { FormSection, reduxForm, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import FormActions from 'components/form/FormActions';
import renderTextInput from 'components/form/RenderTextInput';
import renderSelectInput from 'components/form/RenderSelectInput';
import renderToggleButton from 'components/form/RenderToggleButton';
import InputHelp from 'components/form/InputHelp';

import ProportionInput from 'components/form/toolRun/ProportionInput';
import Settings from 'components/form/toolRun/Settings';
import InterventionProportionHeader from 'components/form/toolRun/InterventionProportionHeader';

import { numericality, required } from 'redux-form-validators';

import { discount_settings_help,
  user_defined_intervention_help,
  user_defined_cvd_relative_risk_help,
  user_defined_current_proportion_undergoing_intervention_help,
  user_defined_target_proportion_undergoing_intervention_help,
  user_defined_duration_of_effect_help,
  intervention_main_help,
  intervention_detection_current_proportion_help,
  intervention_detection_target_proportion_help,
  phased_help,
  prematureDeathHelp
} from 'data/form_help_text';
import { user_defined_interventions } from 'data/intervention'
import { moveToPhased, moveToYear } from 'actions';
import LocalityData from 'services/localityData';
import { normalizeCurrency, normalizeRange, normalizeRangeInt } from 'services/normalizers';

export class InterventionFormContainer extends Component  {
  constructor(props) {
    super(props);

    this.state = {
      original_values: new LocalityData(this.props.locality).defaultValuesForIntervention()
    };
  }

  handleKeyDown = function (e, cb) {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      cb();
    }
  };

  render() {
    const { handleSubmit, previousPage, phased, formValues, loading } = this.props;
    const { original_values } = this.state;

    return (
      <form onSubmit={handleSubmit} onKeyDown={(e) => {this.handleKeyDown(e, handleSubmit)}} id="interventionForm">
        <div className="proportion-forms">
          <h1 className="pt-2">I want to change the CVD related interventions in the population <InputHelp text={intervention_main_help} /></h1>
          <div className="row align-items-center">
            <div className="col-8">
              <h2>I want to improve usage of key interventions for people at risk of CVD</h2>
            </div>
            <div className="col-4 text-right">
              <InputHelp text={phased_help} />
              <Field
                name="phased"
                component={renderToggleButton}
                label="Changes phased in over time?"
                type="checkbox"
                className="phaseToggleButton"
                wrapClassName="d-inline-block"
                onToggleOn={() => this.props.moveToPhased(formValues)}
                onToggleOff={() => this.props.moveToYear(formValues)} />
            </div>
          </div>
          <FormSection name="intervention">
            <InterventionProportionHeader
              phased={phased}
              currentText="Current Proportion Eligible Undergoing Each Intervention"
              targetText="Target Proportion Eligible Undergoing Each Intervention"
              currentHelp={intervention_detection_current_proportion_help}
              targetHelp={intervention_detection_target_proportion_help} />

            <ProportionInput
              label_name="NHS Health Checks"
              name="nhs_health_checks"
              phased={phased}
              originalValue={original_values.intervention.nhs_health_checks.targetProportion} />

            <ProportionInput
              label_name="Annual Review"
              name="annual_review"
              phased={phased}
              originalValue={original_values.intervention.annual_review.targetProportion} />

            <ProportionInput
              label_name="Detection of FH and Cascade Testing"
              name="detection_of_fh_and_cascade_testing"
              phased={phased}
              originalValue={original_values.intervention.detection_of_fh_and_cascade_testing.targetProportion} />

            <ProportionInput
              label_name="Lipid Modification Therapy"
              name="lipid_modification_therapy"
              phased={phased}
              originalValue={original_values.intervention.lipid_modification_therapy.targetProportion} />

            <ProportionInput
              label_name="Anti-hypertensive Therapy"
              name="anti_hypertensive_therapy"
              phased={phased}
              originalValue={original_values.intervention.anti_hypertensive_therapy.targetProportion} />

            <ProportionInput
              label_name="Anticoagulant Therapy"
              name="anticoagulant_therapy"
              helpText={prematureDeathHelp}
              phased={phased}
              originalValue={original_values.intervention.anticoagulant_therapy.targetProportion} />

            <ProportionInput
              label_name="Blood Glucose Lowering Medication"
              name="blood_glucose_lowering_medication"
              phased={phased}
              originalValue={original_values.intervention.blood_glucose_lowering_medication.targetProportion} />

            <ProportionInput
              label_name="NHS Diabetes Prevention Programme"
              name="nhs_diabetes_prevention_programme"
              phased={phased}
              originalValue={original_values.intervention.nhs_diabetes_prevention_programme.targetProportion} />

            <ProportionInput
              label_name="Structured Diabetes Education"
              name="structured_diabetes_education"
              phased={phased}
              originalValue={original_values.intervention.structured_diabetes_education.targetProportion} />

            <ProportionInput
              label_name="Weight Management Programmes"
              name="weight_management_programme"
              phased={phased}
              originalValue={original_values.intervention.weight_management_programme.targetProportion} />

            <ProportionInput
              label_name="Smoking Cessation Programmes"
              name="smoking_cessation_programmes"
              phased={phased}
              originalValue={original_values.intervention.smoking_cessation_programmes.targetProportion} />

            <ProportionInput
              label_name="Nutritional Advice for CKD"
              name="nutritional_advice_for_ckd"
              phased={phased}
              originalValue={original_values.intervention.nutritional_advice_for_ckd.targetProportion} />

            <ProportionInput
              label_name="Insulin Pump"
              name="insulin_pump"
              phased={phased}
              originalValue={original_values.intervention.insulin_pump.targetProportion} />

            <ProportionInput
              label_name="Pharmacist New Medicine Review"
              name="pharmacist_new_medicine_review"
              phased={phased}
              originalValue={original_values.intervention.pharmacist_new_medicine_review.targetProportion} />

            <ProportionInput
              label_name="Blood Pressure Self Monitoring"
              name="blood_pressure_self_monitoring"
              phased={phased}
              originalValue={original_values.intervention.blood_pressure_self_monitoring.targetProportion} />

          </FormSection>
        </div>
        <h2 className="d-flex align-items-center mr-auto">User Defined Intervention <InputHelp text={user_defined_intervention_help} className="ml-2" /></h2>
        <FormSection name="user_defined_intervention" id="user_defined_intervention">
          <div className="row">
            <div className="col">
              <Field
                name="cvd_risk_factor"
                data={ user_defined_interventions }
                component={renderSelectInput}
                label="CVD Risk Factor"
                validate={[required()]}
              />
            </div>
            <div className="col">
              <InputHelp text={user_defined_cvd_relative_risk_help} className="float-right" />
              <Field name="cvd_relative_risk"
                component={renderTextInput}
                label="CVD Relative Risk"
                type="number"
                normalize={normalizeRange(0.0, 1.0)}
                step="0.01"
                validate={[numericality({ '>': 0.0, '<=': 1.0 })]}
                />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <InputHelp text={user_defined_current_proportion_undergoing_intervention_help} className="float-right" />
              <Field name="current_eligible_proportion_undergoing_intervention"
                component={renderTextInput}
                label="Current Eligible Proportion Undergoing Intervention"
                type="number"
                addonSuffix="%"
                step="0.1"
                validate={[numericality({ '>=': 0.0, '<=': 100.0 })]}
                />
            </div>
            <div className="col">
              <InputHelp text={user_defined_target_proportion_undergoing_intervention_help} className="float-right" />
              <Field name="target_eligible_proportion_undergoing_intervention"
                component={renderTextInput}
                label="Target Eligible Proportion Undergoing Intervention"
                type="number"
                addonSuffix="%"
                step="0.1"
                validate={[numericality({ '>=': 0.0, '<=': 100.0 })]}
                />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Field name="upfront_intervention_cost_per_person"
                component={renderTextInput}
                label = "Upfront Intervention Cost Per Person"
                type="number"
                normalize={normalizeCurrency}
                addonPrefix="£"
                step="0.01"
                validate={[numericality({ '>=': 0.0 })]}
              />
            </div>
            <div className="col">
              <Field name="subsequent_annual_intervention_cost_per_person"
                component={renderTextInput}
                label="Subsequent Annual Intervention Cost Per Person"
                type="number"
                normalize={normalizeCurrency}
                addonPrefix="£"
                step="0.01"
                validate={[numericality({ '>=': 0.0 })]}
                />
            </div>
          </div>
          <InputHelp text={user_defined_duration_of_effect_help} className="float-right" />
          <Field name="duration_of_effect"
            component={renderTextInput}
            label="Duration of effect (years)"
            type="number"
            steps="1"
            normalize={normalizeRangeInt(0, 20)}
            />
        </FormSection>
        <h2 className="d-flex align-items-center mr-auto">Settings <InputHelp text={discount_settings_help} className="ml-2" /></h2>
        <FormSection name="settings">
          <Settings />
        </FormSection>
        <FormActions onPreviousClick={previousPage} nextButtonText="Run Model" loading={loading} />
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    phased: state.form.toolRun.values.phased,
    locality: state.form.toolRun.values.locality.label,
    formValues: getFormValues('toolRun')(state)
  };
};

export const ConnectedInterventionFormContainer = connect(mapStateToProps, { moveToPhased, moveToYear })(InterventionFormContainer);

export default reduxForm({
  form: 'toolRun',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(ConnectedInterventionFormContainer)
