// File to contain the data transformations needed to convert the output to chart.js

/*
  Use the following function like

  const groupedData = groupBy(dataset, entry => entry.variable);

  groupedData.get('LY').map(value => { return { x: value.year, y: value.value } }),
*/

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
          map.set(key, [item]);
      } else {
          collection.push(item);
      }
  });
  return map;
}

export function arrayOfNumbers(length) {
  return Array.from(Array(length + 1).keys()).splice(1)
}

export function buildOptions(title, xAxisLabel, yAxisLabel) {
  return {
    title: {
        display: true,
        text: title,
        fontSize: 28,
        fontStyle: 'normal',
        fontColor: '#000'
    },
    scales: {
      xAxes: [{ scaleLabel: { display: true, labelString: xAxisLabel, fontSize: 18 } } ],
      yAxes: [{ scaleLabel: { display: true, labelString: yAxisLabel, fontSize: 18 } } ]
    },
    elements: {
      line: {
        tension: 0
      }
    }
  }
}

export function buildDataSet(data, label, color, fill = false, optional) {
  return {
    ...{
      label: label,
      data: data.map(mapFunc),
      borderColor: color,
      backgroundColor: color,
      fill: fill
    }, ...optional
  }
}

const mapFunc = value => { return { x: value.year, y: value.value } }

export function formatThousands(value, index, values) {
  return (value / 1000).toLocaleString()
}
