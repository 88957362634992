/*
* Reduction in cumulative CVD events over time
*/

import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';

import { groupBy, arrayOfNumbers, buildOptions, buildDataSet, formatThousands } from '../../../services/figures/data_utils';

export default class Chart3 extends Component {
  render() {
    const { data, years, id } = this.props;

    const fixedData = groupBy(data, entry => entry.variable);
    let options = buildOptions(['Figure 3: Intervention costs, total savings and net', 'costs/savings over time (cumulative)'], 'Year', "Change in Cost/Savings (£ '000s)");
    options.scales.yAxes[0].ticks = {};
    options.scales.yAxes[0].ticks.callback = formatThousands;
    options.layout = { padding: { right: 50 } }

    return(
      <div id={id}>
        <Line
          height={200}
          data={{
            labels: arrayOfNumbers(years),
            datasets: [
              buildDataSet(fixedData.get('TOT_INTVN_COSTS'), 'Intervention costs', '#FF0000'),
              buildDataSet(fixedData.get('TOTAL_SAVINGS'), 'Total Savings', '#000000'),
              buildDataSet(fixedData.get('NET_COSTorSAVINGS'), 'Net Cost/Savings', '#6600CC')
            ]
          }}
          options={options}
        />
      </div>
    )
  }
}
