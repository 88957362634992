// Page to show on submission in the CVD Tool form flow
import React, { Component } from 'react';

export default class Submit extends Component  {
  render() {
    let { modelRunId } = this.props

    return (
      <div id="submitForm">
        <h1>Your model has been submitted</h1>
        <p className="lead">Your model will now be run on our servers.
          Once results are available, you will receive an email. Model runs
          normally complete within 12 hours.</p>
      </div>
    )
  }
}
