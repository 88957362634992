export const NEW_ALERT = "NEW_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export function newAlert(options) {
  options.id = Date.now();
  return (dispatch) => {
    let timeout = setTimeout(dispatch(removeAlert(options.id)), 6000);
    dispatch({
      type: NEW_ALERT,
      payload: options
    });
  }
}

export function removeAlert(id) {
  return {
    type: REMOVE_ALERT,
    payload: id
  };
}
