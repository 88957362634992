/*
* Reduction in cumulative CVD events over time
*/

import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';

import { groupBy, arrayOfNumbers, buildOptions, buildDataSet } from '../../../services/figures/data_utils';

export default class Chart1 extends Component {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();
  }
  render() {
    const { data, years, id } = this.props;

    const fixedData = groupBy(data, entry => entry.variable);

    let options = buildOptions('Figure 1: Cumulative CVD events over time', 'Year', 'Change in Number of Events')
    options.layout = { padding: { right: 50 } }

    return(
      <div id={id}>
        <Line
          data={{
            labels: arrayOfNumbers(years),
            datasets: [
              buildDataSet(fixedData.get('MI'), 'Heart Attacks', '#FF6600'),
              buildDataSet(fixedData.get('UANG'), 'Unstable Angina', '#33FF66'),
              buildDataSet(fixedData.get('SANG'), 'Stable Angina', '#000099'),
              buildDataSet(fixedData.get('STRO'), 'Stroke', '#999900'),
              buildDataSet(fixedData.get('TIA'), 'TIA', '#339999'),
              buildDataSet(fixedData.get('CHF'), 'Congestive HF', '#CC3333'),
              buildDataSet(fixedData.get('CVD'), 'Total CVD', '#333333')
            ]
          }}
          options={options}
        />
      </div>
    )
  }
}
