import { required, email } from 'redux-form-validators';

const validations = {
  run_name: [required()],
  email: [required(), email()],
  locality: [required()],
  type: [required()]
}

export const validate = (values) => {
  const errors = {}
  for (let field in validations) {
    let value = values[field]
    errors[field] = validations[field].map(validateField => {
      return validateField(value, values)
    }).find(x => x)
  }
  return errors
}
