import React, { Component } from 'react';
import { Provider } from 'react-redux';

import NewRun from 'containers/NewRun';
import Alerts from 'containers/Alerts';
import Navbar from 'components/Navbar';
import ErrorBoundary from 'components/ErrorBoundary';
import { store } from 'stores';

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <React.Fragment>
          <Alerts />
          <Navbar />
          <ErrorBoundary>
            <NewRun />
          </ErrorBoundary>
        </React.Fragment>
      </Provider>
    )
  }
  
}

export default App;
