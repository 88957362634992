import React, { Component } from 'react'
import Slider from 'rc-slider';

const renderRangeSlider = ({ input, label, defaultValue, min, max, marks, step, meta: { touched, error } }) => (
  <div className="form-group">
    <label>{label}</label>
    <Slider {...input} step={step} value={input.value !== '' ? parseInt(input.value) : defaultValue} min={min} max={max} marks={marks} />
    {touched && error && <div className="invalid-feedback">{error}</div>}
  </div>
)

export default renderRangeSlider;
