import { store } from '../stores';
import LocalityData from '../services/localityData';

export const NEXT_PAGE = "NEXT_PAGE";
export const PREV_PAGE = "PREV_PAGE";
export const PAGE_BACK = 'PAGE_BACK';
export const SUBMIT_DATA_LOADING = 'SUBMIT_DATA_LOADING';
export const SUBMIT_DATA_SUCCESS = 'SUBMIT_DATA_SUCCESS';
export const SUBMIT_DATA_ERROR = 'SUBMIT_DATA_ERROR';
export const MOVE_TO_PHASED = 'MOVE_TO_PHASED';
export const MOVE_TO_YEAR = 'MOVE_TO_YEAR';
export const LOAD_LOCALITY_DATA = 'LOAD_LOCALITY_DATA';

export function nextPage(current_page) {
  history.pushState(store.getState(), "");
  return dispatch => {
    const page = current_page + 1;

    dispatch({
      type: NEXT_PAGE,
      payload: page
    });
    // If we're on Page 2, then dispatch an event to load the locality data into
    // the form.
    if(page === 2) {
      dispatch(loadLocalityData(store.getState().form.toolRun.values));
    }
  };
}

export function prevPage(current_page) {
  history.back();

  return {
    type: PREV_PAGE,
    payload: current_page - 1
  };
}

export function pageBack(state) {
  return {
    type: PAGE_BACK,
    payload: state
  }
}

export function submitData() {
  let state = store.getState()
  let submit_body = { model_run: {
      input_data: JSON.stringify(state),
      name: state.form.toolRun.values.run_name,
      email: state.form.toolRun.values.email
    }
  }

  let request = fetch(`${location.origin}/api/v1/model_runs`, { method: "POST", headers: { 'content-type': 'application/json' }, body: JSON.stringify(submit_body) } )

  return (dispatch) => {
    dispatch({
      type: SUBMIT_DATA_LOADING,
      payload: request
    });

    request.then(data => {
      if(data.status >= 300) {
        dispatch({
          type: SUBMIT_DATA_ERROR,
          payload: request
        });
      } else {
        data.json().then(body => {
          dispatch({
            type: SUBMIT_DATA_SUCCESS,
            payload: body
          });
        }).catch(error => {
          dispatch({
            type: SUBMIT_DATA_ERROR,
            payload: request
          });
        });
      }
    });
  }
}

export function moveToPhased(data) {
  let form = { ...data };
  if(form.type.value == 'risk') {
    for (let factor in form.detection) {
      let value = form.detection[factor].targetProportion;
      form.detection[factor].targetProportion = {};
      form.detection[factor].targetProportion.year_1 = value;
      form.detection[factor].targetProportion.year_2 = value;
      form.detection[factor].targetProportion.year_3 = value;
    }
    for (let factor in form.management) {
      let value = form.management[factor].targetProportion;
      form.management[factor].targetProportion = {};
      form.management[factor].targetProportion.year_1 = value;
      form.management[factor].targetProportion.year_2 = value;
      form.management[factor].targetProportion.year_3 = value;
    }
  } else if(form.type.value == 'intervention') {
    for (let factor in form.intervention) {
      let value = form.intervention[factor].targetProportion;
      form.intervention[factor].targetProportion = {};
      form.intervention[factor].targetProportion.year_1 = value;
      form.intervention[factor].targetProportion.year_2 = value;
      form.intervention[factor].targetProportion.year_3 = value;
    }
  }
  form.phased = true;

  return {
    type: MOVE_TO_PHASED,
    payload: form
  }
}

export function moveToYear(data) {
  let form = { ...data };

  if(form.type.value == 'risk') {
    for (let factor in form.detection) {
      let toSet = 0;

      if(form.detection[factor].targetProportion.year_1 != undefined || form.detection[factor].targetProportion.year_1 != null) {
        toSet = form.detection[factor].targetProportion.year_1;
      }
      form.detection[factor].targetProportion = toSet;
    }
    for (let factor in form.management) {
      let toSet = 0;

      if(form.management[factor].targetProportion.year_1 != undefined || form.management[factor].targetProportion.year_1 != null) {
        toSet = form.management[factor].targetProportion.year_1;
      }
      form.management[factor].targetProportion = toSet;
    }
  } else if(form.type.value == 'intervention') {
    for (let factor in form.intervention) {
      form.intervention[factor].targetProportion = form.intervention[factor].targetProportion.year_1
    }
  }
  form.phased = false;

  return {
    type: MOVE_TO_YEAR,
    payload: form
  }
}

export function loadLocalityData(data) {
  let form = { ...data };
  const localityData = new LocalityData(form.locality.label);
  if(form.type.value == 'risk') {
    const newValues = localityData.defaultValuesForRisk();
    form.detection = newValues.detection;
    form.management = newValues.management;
    form.settings = newValues.settings;
  } else if(form.type.value == 'intervention') {
    const newValues = localityData.defaultValuesForIntervention();
    form.intervention = newValues.intervention;
    form.user_defined_intervention = newValues.user_defined_intervention;
    form.settings = newValues.settings;
  }

  return {
    type: LOAD_LOCALITY_DATA,
    payload: form
  }
}
