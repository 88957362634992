// Step 1 in the CVD Tool form

import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate } from './Locality.form.validate';

import FormActions from 'components/form/FormActions';
import renderTextInput from 'components/form/RenderTextInput';
import renderSelectInput from 'components/form/RenderSelectInput';

import { localities } from 'data/localities';
import { questionTypes } from 'data/questionTypes';
import { locality_help } from 'data/form_help_text';

class LocalityForm extends Component  {
  render() {
    const { handleSubmit, loading } = this.props;

    return (
      <div>
        <h1>Details</h1>
        <form onSubmit={handleSubmit} id="localityForm">
          <Field
            name="run_name"
            component={renderTextInput}
            label="What do you want to call this model run?"
            hint="e.g. 'Increased Diabetes Detection'"
          />
          <Field
            name="email"
            component={renderTextInput}
            label="What email address shall we send results to?"
          />
          <Field
            name="locality"
            data={localities}
            component={renderSelectInput}
            label="What locality would you like to base this run on?"
            hint="(type into box to narrow selection)"
          />
          <Field
            name="type"
            data={ questionTypes }
            component={renderSelectInput}
            label="What type of question you would like to answer"
            help_text={locality_help}
            modalClassName="modal-lg"
          />

          <FormActions showPrevious={false} />
        </form>
      </div>
    )
  }
}

export default reduxForm({
  form: 'toolRun',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
})(LocalityForm)
