import { createStore, applyMiddleware, compose } from 'redux';
import promiseMiddleware from 'redux-promise';
import ReduxThunk from 'redux-thunk';
import reducers from '../reducers';

import Raven from "raven-js";
import createRavenMiddleware from "raven-for-redux";

var body = document.getElementsByTagName('body')[0];
let environment = body.dataset.environment

if (environment !== 'development' && environment !== 'test') {
  Raven.config('https://394df043b0a2413a9c2e62790159d6bd@sentry.epigenesys.org.uk/10').install();
}

let previousValues = {};
if(history.state !== null && history.state !== undefined) {
  previousValues = history.state;
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(reducers, previousValues, composeEnhancers(applyMiddleware(promiseMiddleware, ReduxThunk, createRavenMiddleware(Raven))));

export function createStoreWithValues(values) {
  return createStore(reducers, values, composeEnhancers(applyMiddleware(promiseMiddleware, ReduxThunk, createRavenMiddleware(Raven))));
}
