/*
* Reduction in cumulative CVD events over time
*/

import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';

import { groupBy, arrayOfNumbers, buildOptions, buildDataSet, formatThousands } from '../../../services/figures/data_utils';

export default class Chart4 extends Component {
  render() {
    const { data, years, id } = this.props;

    const fixedData = groupBy(data, entry => entry.variable);

    let options = buildOptions(['Figure 4: Primary care, secondary care,', 'and social care savings over time (cumulative)'], 'Year', "Gross Savings (£ '000s)");
    options.scales.yAxes[0].stacked = true;
    options.scales.yAxes[0].ticks = {};
    options.scales.yAxes[0].ticks.reverse = true;
    options.scales.yAxes[0].ticks.callback = formatThousands;
    options.layout = { padding: { right: 50 } }

    return(
      <div id={id}>
        <Line
          height={200}
          data={{
            labels: arrayOfNumbers(years),
            datasets: [
              buildDataSet(fixedData.get('SOCIAL_COST'), 'Social Care', '#99FF66', true),
              buildDataSet(fixedData.get('SECCOST'), 'Secondary Care', '#339966', true),
              buildDataSet(fixedData.get('PRICOST'), 'Primary Care', '#33CCCC', true)
            ]
          }}
          options={options}
        />
      </div>
    )
  }
}
