import { combineReducers } from 'redux';
import WizardReducer from './wizard.reducer';
import AlertsReducer from './alert.reducer';
import ToolRunFormReducer from './toolRunForm.reducer';
import OutputFormReducer from './outputForm.reducer';

import { reducer as FormReducer } from 'redux-form';

import { PAGE_BACK } from '../actions';

const applicationReducer = combineReducers({
  wizard: WizardReducer,
  form: FormReducer.plugin({
    toolRun: (state, action) => ToolRunFormReducer(state, action),
    output: (state, action) => OutputFormReducer(state, action)
  }),
  alerts: AlertsReducer
});

const rootReducer = (state, action) => {
  switch(action.type){
    case PAGE_BACK:
      if(action.payload === null) {
        window.history.back();
      } else {
        state = action.payload;
      }
      break;
    case '@@redux-form/BLUR':
      history.replaceState(state, "");
      break;
  }

  return applicationReducer(state, action)
}

export default rootReducer;
