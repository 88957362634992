import { NEW_ALERT, REMOVE_ALERT } from '../actions/alert.actions';
import { SUBMIT_DATA_ERROR } from '../actions';

export default function(state = [], action) {
  switch(action.type) {
  case NEW_ALERT:
    return [...state, action.payload];
  case REMOVE_ALERT:
    return state.filter(alert => alert.id !== action.payload);
  case SUBMIT_DATA_ERROR:
    return [...state, { type: 'danger', text: 'The server has returned an error, and we have been alerted to this issue.', id: Date.now() } ]
  }

  return state;
}
