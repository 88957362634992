/*
* Reduction in cumulative CVD events over time
*/

import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';

import { groupBy, arrayOfNumbers, buildOptions, buildDataSet } from '../../../services/figures/data_utils';

export default class Chart2 extends Component {
  render() {
    const { data, years, id } = this.props;

    const fixedData = groupBy(data, entry => entry.variable);

    let options = buildOptions('Figure 2: Life years and premature mortality cases over time  (cumulative)', 'Year', 'Premature Mortality');
    options.scales.yAxes[0].id = 'PREM';
    options.scales.yAxes[0].gridLines = { color: 'rgba(255, 255, 255, 0)'}
    options.scales.yAxes.push({ id: 'LY', scaleLabel: { display: true, labelString: 'Life Years Gained', fontSize: 18 }, position: 'right', gridLines: { color: 'rgba(255, 255, 255, 0)'} })
    options.layout = { padding: { right: 50 } }

    return(
      <div id={id}>
        <Line
          height={200}
          data={{
            labels: arrayOfNumbers(years),
            datasets: [
              buildDataSet(fixedData.get('LY'), 'Life years gained', '#66CC99', false, { yAxisID: 'LY' }),
              buildDataSet(fixedData.get('PREM'), 'Premature mortality', '#990000', false, { yAxisID: 'PREM' })
            ]
          }}
          options={options}
        />
      </div>
    )
  }
}
