import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/fontawesome-free-solid';

import ModelRunInputsRiskFactor from './ModelRunInputsRiskFactor';
import ModelRunInputsIntervention from './ModelRunInputsIntervention';

class ModelRunInputs extends Component {
  constructor(props) {
    super(props);
    
    this.state = { collapse: false }
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse })
  }

  render() {
    const { input_data } = this.props;

    let model_inputs;

    if(input_data.type.value === 'risk') {
      model_inputs = <ModelRunInputsRiskFactor input_data={input_data} />
    } else {
      model_inputs = <ModelRunInputsIntervention input_data={input_data} />
    }

    const toRender = (model_inputs)

    return (
      <div id="runInputs" className="container">
        <h2 className="mt-0" onClick={this.toggle}>Form inputs { this.state.collapse ? <FontAwesomeIcon icon={fa.faChevronUp} /> : <FontAwesomeIcon icon={fa.faChevronDown} /> }</h2>
        <Collapse isOpen={this.state.collapse}>
          <div className="inputs">
            <div>
              <div className="row inputRow">
                <div className="col-2 field">Locality</div>
                <div className="col-10 value">{input_data.locality.label}</div>
              </div>
              <div className="row inputRow">
                <div className="col-2 field">Type</div>
                <div className="col-10 value">{input_data.type.label}</div>
              </div>
            </div>
            <h4>Model Inputs</h4>
            { toRender }
          </div>
        </Collapse>
      </div>
    )
  }
}

export default ModelRunInputs;
