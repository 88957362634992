/*
* Reduction in cumulative CVD events over time
* Chart 6, Chart 7, Chart 8, Chart 9
*/

import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';

import { groupBy, arrayOfNumbers, buildOptions, buildDataSet, formatThousands } from '../../../services/figures/data_utils';

export default class RiskCondition extends Component {
  render() {
    const { data, years, title, yAxisLabel } = this.props;

    const fixedData = groupBy(data, entry => entry.variable);

    let options = buildOptions(title, 'Year', yAxisLabel);
    options.scales.yAxes[0].ticks = {};
    options.scales.yAxes[0].ticks.callback = value => value.toLocaleString();
    options.layout = { padding: { right: 50 } }

    const iterator = fixedData.keys();

    return(
      <Line
        height={200}
        data={{
          labels: arrayOfNumbers(years),
          datasets: [
            buildDataSet(fixedData.get(iterator.next().value), 'QRISK', '#FF6600'),
            buildDataSet(fixedData.get(iterator.next().value), 'Hypertension', '#33FF66'),
            buildDataSet(fixedData.get(iterator.next().value), 'Atrial Fibrillation', '#000099'),
            buildDataSet(fixedData.get(iterator.next().value), 'Diabetes', '#999900'),
            buildDataSet(fixedData.get(iterator.next().value), 'Non-diab. hypergl.', '#339999'),
            buildDataSet(fixedData.get(iterator.next().value), 'CKD', '#CC3333')
          ]
        }}
        options={options}
      />
    )
  }
}
