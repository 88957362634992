/*
  Reducer for the toolRun form. Use this file if you want to edit
  the values of the form programmatically e.g. from an API or file.
*/

import { LOAD_LOCALITY_DATA, MOVE_TO_PHASED, MOVE_TO_YEAR } from '../actions';

export default function(state, action) {
  switch(action.type) {
    case '@@redux-form/REGISTER_FIELD':
      if(action.payload.name === 'years') {
        return { ...state, values: { years: 5 } };
      }
    default:
      return state;
  }
}
