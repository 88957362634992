import React, { Component } from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/fontawesome-free-solid';

const renderToggleButton = ({className, input, label, onToggleOn, onToggleOff, wrapClassName}) => (
  <div className={`btn-group-toggle ${wrapClassName}`}>
    <label className={`btn ${input.value ? 'btn-primary' : 'btn-outline-secondary'} ${className}`}>
      <input {...input} type="checkbox" onChange={(e) => { input.onChange(e); toggleCheckbox(e, onToggleOn, onToggleOff); }} /> {label}
      <FontAwesomeIcon icon={`${input.value ? 'check' : 'times'}`} className="fas ml-2" />
    </label>
  </div>
)

function toggleCheckbox(e, onTrue, onFalse) {
  if(e.target.value === 'false' || e.target.value === '') {
    onTrue();
  } else {
    onFalse();
  }
}

export default renderToggleButton;
