/*
* Reduction in cumulative CVD events over time
*/

import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';

import { groupBy, arrayOfNumbers, buildOptions, buildDataSet, formatThousands } from '../../../services/figures/data_utils';

export default class Chart5 extends Component {
  render() {
    const { data, years, id } = this.props;

    const fixedData = groupBy(data, entry => entry.variable);

    let options = buildOptions('Figure 5: Net monetary benefit over time (cumulative)', 'Year', "Net Monetary Benefit (£'000s)");
    options.scales.yAxes[0].ticks = {};
    options.scales.yAxes[0].ticks.callback = formatThousands;
    options.layout = { padding: { right: 50 } }

    return(
      <div id={id}>
        <Line
          height={200}
          data={{
            labels: arrayOfNumbers(years),
            datasets: [
              buildDataSet(fixedData.get('NET_MONETARY_BENFT'), 'Net Monetary Benefit', '#9999CC')
            ]
          }}
          options={options}
        />
      </div>
    )
  }
}
