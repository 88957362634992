import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { nextPage, prevPage, pageBack, submitData } from 'actions';
import { store, setReduxState } from 'stores';
import Form from 'components/Form';

class NewRun extends Component {
  componentDidMount() {
    if(history.state === null) {
      history.pushState(store.getState(), "");
    }

    window.onpopstate = (event) => {
      this.props.pageBack(event.state);
    }
  }

  render() {
    const { page, type, modelRunId, loading } = this.props;

    return (
      <Fragment>
        <div className="container">
          <Form 
            page={page}
            type={type}
            onNextPage={this.props.nextPage}
            onPrevPage={this.props.prevPage}
            onSubmit={this.props.submitData}
            modelRunId={modelRunId}
            loading={loading} />
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps({ wizard, form }) {
  let type = ""
  if (form.toolRun && form.toolRun.values && form.toolRun.values.type && form.toolRun.values.type.value) {
    type = form.toolRun.values.type.value
  }
  let modelRunId = ""
  if (wizard.response && wizard.response.id) {
    modelRunId = wizard.response.id
  }
  return {
    page: wizard.page,
    loading: wizard.loading,
    type: type,
    modelRunId: modelRunId
  }
}

export default connect(mapStateToProps, { nextPage, prevPage, pageBack, submitData })(NewRun)
