export function normalizeProportion(value) {
  let new_value = value;

  let matched = value.match(/^$|^\d{1,3}(\.(\d{1,2})?)?/g)
  if(matched !== undefined && matched.length > 0) {
    new_value = matched[0]
  }

  return new_value;
}

export function normalizeCurrency(value) {
  let new_value = value;

  let matched = value.match(/^$|^\-?\d+(\.(\d{1,2})?)?/g)
  if(matched !== undefined && matched.length > 0) {
    if(parseInt(matched[0]) < 0) {
      new_value = 0;
    } else {
      new_value = matched[0]
    }
  }

  return new_value;
}

export const normalizeRange = (min, max) => (value) => {
  const parsed = parseFloat(value);

  if(parsed < min) {
    return min.toString();
  } else if (parsed > max) {
    return max.toString();
  }
  return value;
}

export const normalizeRangeInt = (min, max) => (value) => {
  return parseInt(normalizeRange(min, max)(value)).toString();
}