import React, { Component } from 'react';

class renderTextInput extends Component {
  render() {
    const { input, label, labelClassName, placeholder, type, hint, originalValue, min, max, step, addonPrefix, addonSuffix, meta: { touched, error } } = this.props;

    let changedClass = input.value && parseFloat(input.value.replace(/(\$|%|£)/g, '')) !== parseFloat(originalValue) ? 'changed' : ''

    return (<div className={`form-group ${input.name}`}>
      <label className={labelClassName}>{label}</label>
      <div className="input-group">
        {(addonPrefix && <div className="input-group-prepend">
          <span className="input-group-text">{addonPrefix}</span>
        </div>)}
        <input {...input} placeholder={placeholder} type={type} min={min} max={max} step={step} className={`form-control ${changedClass} ${touched && error ? 'is-invalid' : ''}`} />
        {(addonSuffix && <div className="input-group-append">
          <span className="input-group-text">{addonSuffix}</span>
        </div>)}
      </div>
      {hint && <small className='form-text text-muted'>{hint}</small>}
      {touched && error && typeof error === 'string' && <div className="invalid-feedback d-block">{error}</div>}
    </div>)
  }
}

export default renderTextInput;
