/*
  Reducer for the toolRun form. Use this file if you want to edit
  the values of the form programmatically e.g. from an API or file.
*/

import { LOAD_LOCALITY_DATA, MOVE_TO_PHASED, MOVE_TO_YEAR } from '../actions';

export default function(state, action) {
  switch(action.type) {
    case MOVE_TO_PHASED:
      return { ...state, values: action.payload };
    case MOVE_TO_YEAR:
      return { ...state, values: action.payload };
    case LOAD_LOCALITY_DATA:
      return { ...state, values: action.payload };
    default:
      return state;
  }
}
