import React, { Component } from 'react';

class ModelRunInputHeader extends Component {

  render() {
    const { phased, currentText, targetText } = this.props;

    let toRender;

    if(phased) {
      toRender = (
        <div className="row inputHead">
          <div className="col-4"></div>
          <div className="col head">{currentText}</div>
          <div className="col head">{`${targetText} Year 1`}</div>
          <div className="col head">{`${targetText} Year 2`}</div>
          <div className="col head">{`${targetText} Year 3`}</div>
        </div>
      )
    } else {
      toRender = (
        <div className="row inputHead">
          <div className="col-4"></div>
          <div className="col head">{currentText}</div>
          <div className="col head">{targetText}</div>
        </div>
      )
    }
    return toRender;
  }
}

export default ModelRunInputHeader;
