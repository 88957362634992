import React, { Component } from 'react';

export const locality_help = (
  <div>
    <p><strong>(1) What happens when I improve detection or management of key CVD risk factors?</strong></p>
    <p>This allows users to consider the impact of changing the proportion of people detected or managed out of the people who have a specific CVD risk factor. Included CVD risk factors are: QRISK ≥ 10%; hypertension; familial hypercholesterolaemia; atrial fibrillation; diabetes; non-diabetic hyperglycaemia; and chronic kidney disease.</p>
    <p>Example: If 60% of people who have hypertension are currently detected and 75% of people who have been detected as having hypertension are well managed then the user could test the impact of a future scenario that 70% of people who have hypertension are detected and 80% of people with hypertension that are detected are well managed. In this example both detection and management are being improved. The user could test the impact of an improvement in management only by setting the target detection proportion the same as the current detection proportion.</p>
    <p><strong>(2) What happens when I improve usage of the key interventions for people at risk of CVD?</strong></p>
    <p>This allows users to consider the impact of changing the proportion of people with any CVD risk factor who 'use' specific detection and management interventions. Usage incorporates the proportion offered the intervention (of those eligible), uptake and retention of the intervention. Improvements in usage of the intervention might occur through offering more of the eligible population the intervention, increasing uptake and/ or reducing discontinuation of the intervention. Included interventions are: NHS health checks; annual review; cascade testing for FH; lipid modification therapy; anti-hypertensive therapy; anticoagulant therapy; blood glucose lowering medication; NHS diabetes prevention programme; structured diabetes education; weight management programmes; smoking cessation programmes; nutritional advice for CKD; insulin pump; pharmacist new medicines review; blood pressure self-monitoring; and user defined intervention. These interventions can impact upon multiple risk factors.</p>
    <p>Example: If 83% of people use anticoagulants out of the eligible proportion, the the user could test the impact of a future scenario that 90% of people eligible for anticoagulants use them.</p>
  </div>
)

/** Risk factor help */
export const risk_factor_main_help = (
  <div>
    <p>Review and change the values of risk factor detection and/or management in your selected population. When you enter a target proportion value, the box will highlight indicating that this risk factor change (from current to target) will be used when you click on ‘run model’ at the bottom of the page.</p>
    < p > When the model is run, these target proportions will be achieved by changing usage of a range of interventions. The <a href="/files/Database%20of%20Interventions.xlsx">database of interventions and conditions</a> provides further details about how this is done in the underlying model. < /p>
    <p>Information about the sources of data used to estimate current care proportions and targets for detection and management of high risk conditions, together with information about definitions for each high risk group, what well-managed means in the tool can be found in the accompanying <a href="/files/Database%20of%20Interventions.xlsx">database of interventions and conditions</a>.</p>
  </div>
)

export const risk_detection_current_proportion_help = (
  <div>
    <p>This is the current proportion detected out of the total population estimated to have the risk factor. The estimates are based on local data where possible, and national data otherwise. The source of each of these estimates can be found in the <a href="/files/Database%20of%20Interventions.xlsx">database of interventions and conditions</a>. The user can overwrite these values with their own inputs if they wish.</p>
  </div>
)

export const risk_detection_target_proportion_help = (
  <div>
    <p>Please enter the target proportion detected out of the total population estimated to have the risk factor. You can change the target proportion for up to 7 risk conditions at once. If you are not changing the proportion detected for a condition, then please leave the target proportion as the default value.</p>
    <p>Example: If you want to assess the impact of increasing detection of hypertension to 70% and chronic kidney disease to 60% then change the target proportion for hypertension to 70% and kidney disease to 60% and leave the target proportions as they are for all other risk conditions.</p>
    <p>It is assumed in the model that the target will act indefinitely into the future. If you wish to see the benefit of a single year of improvement only, please select the ‘Changes phased in over time’ option and change targets for year one only.</p>
  </div>
)

export const risk_management_current_proportion_help = (
  <div>
    <p>This is the current proportion well-managed of those individuals detected with the condition. Management is defined in the tool as receiving all interventions that are NICE recommended for CVD prevention in people with that condition. The estimates given in the tool are based on a single key indicator of the proportion currently well managed, based on local data where possible and national data otherwise. The source of each of these estimates, and the interventions that comprise management for each high risk condition can be found in the <a href="/files/Database%20of%20Interventions.xlsx">database of interventions and conditions</a>. The user can overwrite these values with their own inputs if they wish.</p>
  </div>
)

export const risk_management_target_proportion_help = (
  <div>
    <p>Please enter the target proportion well-managed of those individuals detected with the condition. You can change the target proportion for up to 7 risk conditions at once. You can change the proportion well-managed at the same time as changing the proportion detected if needed.</p>
    <p>Example: If you want to assess the impact of increasing management of chronic kidney disease to 70% then change the target proportion for kidney disease to 70% and leave the target proportions as they are for all other risk conditions. </p>
    <p>Within the underlying model, these target proportions will be achieved by changing usage of a range of management interventions. The interventions that comprise management for each high risk condition can be found in the <a href="/files/Database%20of%20Interventions.xlsx">database of interventions and conditions</a>.  It is assumed in the model that the target will act indefinitely into the future. If you wish to see the benefit of a single year of improvement only, please select the ‘Changes phased in over time’ option and change targets for year one only.</p>
  </div>
)


/** Intervention help */
export const intervention_main_help = (
  <div>
    <p> Review and change the usage of interventions in your selected population. When you enter a target proportion value, the box will highlight indicating that this change in intervention usage (from current to target) will be implemented when you click on ‘run model’ at the bottom of the page.</p>
    <p>Information about the sources of data used to estimate current care proportions and targets for intervention usage, together with information about intervention effectiveness, health benefits, costs, duration of effect and eligibility can be found in the accompanying <a href="/files/Database%20of%20Interventions.xlsx">database of interventions and conditions</a>.</p>
  </div>
)

export const intervention_detection_current_proportion_help = (
  <div>
    <p>This is the current proportion being treated with or undergoing the intervention of those individuals eligible. This includes a combination of offer, uptake and discontinuation rates. Eligible individuals may have one or more risk conditions. The current estimates are based on local data where possible, and national data otherwise. The source of each of these estimates, together with the list of eligible high risk groups can be found in the <a href="/files/Database%20of%20Interventions.xlsx">database of interventions and conditions</a>.  The user can overwrite these values with their own inputs if available.</p>
  </div>
)

export const intervention_detection_target_proportion_help = (
  <div>
    <p>Please enter the target usage as a proportion of those individuals who are eligible for the intervention. You can change the target proportion for as many interventions as required. Changes to intervention usage may affect one or more of the risk conditions, depending upon eligibility. Tthe list of eligible high risk groups can be found in the <a href="/files/Database%20of%20Interventions.xlsx">database of interventions and conditions</a>.</p>
    <p>Example: If you want to assess the impact of increasing usage of anticoagulant therapy to 90% of those eligible and smoking cessation to 50% of those eligible then change the target proportion for anticoagulant therapy to 90% and smoking cessation to 50%.</p>
  </div>
)


/** Shared form help */

export const phased_help = (
  <div>
    <p>Users can choose to phase in targets over two or three years if they wish. They can also choose to implement a target in year one or year two, but then return to current care following this. If users wish to phase in targets over two years, they should ensure that the target proportion in year three matches the target proportion in year two. If users wish to implement a target then return to current care, they should ensure that the target proportion for year three matches the current proportion.</p>
  </div>
)

export const discount_settings_help = (
  <div>
    <p>Costs and QALYs are often discounted to account for the fact that people place less weight on what happens in the uncertain future than they do on the present. If a discount rate of 0% is chosen then no discounting will be applied. The higher the discount rate the more future costs and QALYs will be down-weighted. Note that PHE use these default values. PHE’s convention is to apply a 3.5% discount rate to costs and a 1.5% discount rate to QALYs, so these have been set as the default values, but can be adjusted by the user if they wish. If users do not wish to apply discount rates, both values should be set to 0%.</p>
  </div>
)

export const intervention_current_proportion_help = (
  <div>
    <p> This is the current proportion using the intervention of those individuals eligible.
        Individuals may have one or more risk conditions.
        Usage includes a combination of offer, uptake and discontinuation rates.
        The estimates are based on local data were possible, and national data otherwise.
      The source of each of these estimates can be found in the <a href="/files/Database%20of%20Interventions.xlsx">database of interventions and conditions</a>.
      The user can overwrite these values with their own inputs
    if available.</p>
  </div>
)

export const intervention_target_proportion_help = (
  <div>
    <p>Please enter the target usage as a proportion of those individuals who are eligible for the intervention. You can change the target proportion for as many interventions as required. If you are not changing the usage of an intervention, then please leave blank. Changes to intervention usage can affect a range of risk conditions.</p>
    <p>Example: If you want to assess the impact of increasing usage of anticoagulant therapy from 83% to 90% and smoking cessation from 4% to 50% then change the target proportion for anticoagulant therapy to 90% and smoking cessation to 50%. The impacts upon the different risk conditions can be shown in the tool outputs.</p>
  </div>
)

export const user_defined_intervention_help = (
  <div>
    <p>If you would like to consider the impact of an intervention not listed above, you can do so here if you have some information about its effectiveness and costs. </p>
  </div>
)

export const user_defined_cvd_risk_help = (
  <p>Please select the risk factor which you are  aiming to impact with your intervention</p>
)

export const user_defined_cvd_relative_risk_help = (
  <p>Please enter the relative risk associated with the intervention for the CVD risk factor you entered.</p>
)

export const user_defined_current_proportion_undergoing_intervention_help = (
  <div>
    <p>Please include the current proportion using the intervention as a proportion of those eligible (including offer, uptake & discontinuation rates). This can be set to zero.</p>
  </div>
)

export const user_defined_target_proportion_undergoing_intervention_help = (
  <div>
    <p>Please enter the target usage as a proportion of those individuals who are eligible for the intervention. </p>
  </div>
)

export const user_defined_duration_of_effect_help = (
  <div>
    <p>Please enter for how many years you would expect the intervention to reduce CVD risk.</p>
  </div>
)

export const prematureDeathHelp = (
  <div>
    <p>Although highly effective, anticoagulants are also associated with a relatively small risk of major bleeding, which may be fatal. The magnitude of this effect is subject to considerable uncertainty, and the modelled effects on premature mortality when running scenarios that increase anticoagulant usage in this tool may not be statistically significant. Anticoagulants are recommended by NICE for their overall positive impacts on quality and length of life’.</p>
  </div>
)
