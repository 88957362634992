import { NEXT_PAGE, PREV_PAGE, SUBMIT_DATA_SUCCESS, SUBMIT_DATA_LOADING, SUBMIT_DATA_ERROR } from '../actions/index';

export default function(state = { page: 1, loading: false }, action) {
  switch(action.type) {
  case NEXT_PAGE:
    return { page: action.payload };
  case PREV_PAGE:
    return { page: action.payload };
  case SUBMIT_DATA_LOADING:
    return { ...state, loading: true }
  case SUBMIT_DATA_ERROR:
    return { ...state, loading: false }
  case SUBMIT_DATA_SUCCESS:
    return { page: state.page + 1, response: action.payload, loading: false }
  }

  return state;
}
