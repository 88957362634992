import React, { Component } from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/fontawesome-free-solid';

class FormActions extends Component {
  render() {
    let { onPreviousClick, nextButtonText, loading, showPrevious } = this.props;

    let loadingClass = loading ? 'disabled' : '';
    let nextIcon = loading ? 'spinner' : 'angle-right';
    let nextIconClass = loading ? 'fa-spin ml-1' : 'ml-1';

    return (
      <div className="float-right mt-4">
        { showPrevious && <button onClick={onPreviousClick} className={`btn btn-outline-secondary mr-3 ${loadingClass}`} disabled={loading}>
          <FontAwesomeIcon icon="angle-left" className="fas mr-1" />
          Previous
        </button> }
        <button type="submit" className={`btn btn-primary ${loadingClass}`} disabled={loading}>
          {nextButtonText}
          <FontAwesomeIcon icon={nextIcon} className={nextIconClass} />
        </button>
      </div>
    )
  }
}

FormActions.defaultProps = {
  nextButtonText: "Next",
  loading: false,
  showPrevious: true
};

export default FormActions;
