import React, { Component } from 'react';
import { Field, reduxForm, FormSection } from 'redux-form';
import PropTypes from 'prop-types';
import { numericality } from 'redux-form-validators';

import renderTextInput from 'components/form/RenderTextInput';
import Blank from 'components/Blank';
import { normalizeProportion } from 'services/normalizers';
import InputHelp from 'components/form/InputHelp';

class ProportionInput extends Component {

  render() {
    const { label_name, phased, originalValue, name, suggestedTarget, helpText, minimum_input } = this.props;

    let toRender;
    if(phased) {
      toRender = (
        <FormSection
          component={Blank}
          name="targetProportion">
          <div className="col">
            <Field name="year_1"
              component={renderTextInput}
              label={`${label_name} target proportion year 1`}
              labelClassName="sr-only"
              type="number"
              originalValue={originalValue}
              normalize={normalizeProportion}
              min="1"
              max="100"
              step="0.1"
              addonSuffix="%"
              validate={[numericality({ '>=': minimum_input, '<=': 100.0 })]} />
          </div>

          <div className="col">
            <Field name="year_2"
              component={renderTextInput}
              label={`${label_name} target proportion year 2`}
              labelClassName="sr-only"
              type="number"
              originalValue={originalValue}
              normalize={normalizeProportion}
              min="1"
              max="100"
              step="0.1"
              addonSuffix="%"
              validate={[numericality({ '>=': minimum_input, '<=': 100.0 })]} />
          </div>

          <div className="col">
            <Field name="year_3"
              component={renderTextInput}
              label={`${label_name} target proportion year 3`}
              labelClassName="sr-only"
              type="number"
              originalValue={originalValue}
              normalize={normalizeProportion}
              min="1"
              max="100"
              step="0.1"
              addonSuffix="%"
              validate={[numericality({ '>=': minimum_input, '<=': 100.0 })]} />
          </div>
        </FormSection>
      )
    } else {
      toRender = (
        <React.Fragment>
          <div className="col">
            <Field name="targetProportion"
              component={renderTextInput}
              label={`${label_name} target proportion`}
              labelClassName="sr-only"
              type="number"
              originalValue={originalValue}
              normalize={normalizeProportion}
              min="1"
              max="100"
              step="0.1"
              addonSuffix="%"
              validate={[numericality({ '>=': minimum_input, '<=': 100.0 })]} />
          </div>
          {
            suggestedTarget !== undefined && (
              <div className="col-1 text-right"> { suggestedTarget ? `${suggestedTarget}%` : suggestedTarget }</div>
            )
          }

        </React.Fragment>
      )
    }

    let helpTextToRender = "";
    if(helpText) {
      helpTextToRender = (
        <InputHelp text={helpText} className="float-right" />
      )
    }

    return (
      <FormSection className={`form-row ${name}`} name={name}>
        <div className="col-3">
          {helpTextToRender}
          <label>{label_name}</label>
        </div>

        <div className="col">
          <Field name="currentProportion"
            component={renderTextInput}
            label={`${label_name} current proportion`}
            labelClassName="sr-only"
            type="number"
            originalValue={originalValue}
            normalize={normalizeProportion}
            min={minimum_input}
            max="100"
            step="0.1"
            addonSuffix="%"
            validate={[numericality({ '>=': minimum_input, '<=': 100.0 })]} />
        </div>

        { toRender }
      </FormSection>
    )
  }
}

ProportionInput.propTypes = {
  label_name: PropTypes.string,
  minimum_input: PropTypes.number
};

ProportionInput.defaultProps = {
  label_name: 'Default Label',
  minimum_input: 0.0
};

export default ProportionInput;
