import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableCell from './TableCell';

export default class TableRow extends Component {

  columnClassFormHeader(header) {
    const { columnClassNames } = this.props;
    if(columnClassNames === undefined) {
      return ''
    } else {
      return columnClassNames[header]
    }
  }

  render() {
    const { name, values, headers, conditionalFormating, columnClassNames, rowClassNames } = this.props;

    const tableCells = headers.map( header => {
      const value = values[header];
      return (
        <TableCell key={`${name}.${header}`} value={value} columnClassName={this.columnClassFormHeader(header)}  />
      )
    });

    return (
      <tr className={rowClassNames ? rowClassNames[name] : ''}>
        <td>{name}</td>
        {tableCells}
      </tr>
    )
  }
}
