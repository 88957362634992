import React, { Component } from 'react'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import ReactDOM from 'react-dom';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

class InputHelp extends Component {
  constructor() {
    super();
    this.state = {
      opened: false
    }
  }

  modalToggle = () => {
    this.setState({
      opened: !this.state.opened
    });
  }


  render() {
    let { opened } = this.state;
    let { text, modalClassName, className } = this.props;

    return (
      <div className={`input-help ${className ? className : ''}`}>
        <div className="input-help-hover" onClick={this.modalToggle}>
          <FontAwesomeIcon icon="question-circle"/>
        </div>

        <Modal isOpen={opened} toggle={this.modalToggle} className={this.props.modalClassName}>
          <ModalBody>
            {text}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.modalToggle}>Close</Button>
          </ModalFooter>
        </Modal>

      </div>
    )
  }
}

export default InputHelp;
