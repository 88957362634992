import React, { Component } from 'react';

const Alert = ({ type, text, onDismiss, id }) => (
  <div className={`alert alert-${type} alert-dismissible fade show`} role="alert">
    {text}
    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => onDismiss(id)}>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
)

export default Alert;
