import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

export default class TableCell extends Component {
  render() {
    const { value, conditionalFormating, columnClassName } = this.props
    return (
      <td className={columnClassName}>
        {conditionalFormating && conditionalFormating.currency ? `£${value.toLocaleString()}` : value.toLocaleString()}
      </td>
    )
  }
}
