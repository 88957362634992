import React, { Component } from 'react';
import { Field, reduxForm, FormSection } from 'redux-form';
import PropTypes from 'prop-types';
import { numericality } from 'redux-form-validators';

import renderTextInput from 'components/form/RenderTextInput';

export default class Settings extends Component  {
  render() {
    const { label_name, phased, originalValue } = this.props;
    return (<div className="row" id="settings">
      <div className="col">
        <Field name="cost_discount_rate"
          component={renderTextInput}
          label="Cost discount rate"
          addonSuffix="%"
          type="number"
          step="0.1"
          validate={[numericality({ '>=': 0.0, '<=': 100.0 })]}
          />
      </div>
      <div className="col">
        <Field name="qalys_discount_rate"
          component={renderTextInput}
          label="QALYs discount rate"
          addonSuffix="%"
          type="number"
          step="0.1"
          validate={[numericality({ '>=': 0.0, '<=': 100.0 })]}
          />
      </div>
    </div>)
  }
}
