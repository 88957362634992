import React, { Component } from 'react';

const renderCheckbox = ({className, input, label}) => (
  <div className={className}>
    <label htmlFor={input.name}>
      {label}
    </label>
    <input {...input} id={input.name} type="checkbox" /> 
  </div>
)

export default renderCheckbox;
