import React, { Component } from 'react'

import InputHelp from 'components/form/InputHelp';

const RiskFactorProportionHeader = ({ phased, currentText, targetText, currentHelp, targetHelp }) => (
  <div className="form-row proportion-header mb-2 mt-3">
    <div className="col-3"></div>
    <div className="col"><strong>{currentText}</strong><InputHelp text={currentHelp} /></div>
    { phased &&
      <React.Fragment>
        <div className="col"><strong>{`${targetText} Year 1`}</strong><InputHelp text={targetHelp} /></div>
        <div className="col"><strong>{`${targetText} Year 2`}</strong><InputHelp text={targetHelp} /></div>
        <div className="col"><strong>{`${targetText} Year 3`}</strong><InputHelp text={targetHelp} /></div>
      </React.Fragment>
    }
    { !phased &&
      <React.Fragment>
        <div className="col"><strong>{targetText}</strong><InputHelp text={targetHelp} /></div>
        <div className="col-1 text-right"><strong>Suggested Target*</strong></div>
      </React.Fragment>
    }
  </div>
)

export default RiskFactorProportionHeader;
