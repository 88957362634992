import React, { Component } from 'react';

class ModelRunInputTarget extends Component {

  render() {
    const { phased, data } = this.props;

    let toRender;

    if(phased) {
      toRender = (
        <React.Fragment>
          <div className="col value">{data.year_1}</div>
          <div className="col value">{data.year_2}</div>
          <div className="col value">{data.year_3}</div>
        </React.Fragment>
      )
    } else {
      toRender = (
        <div className="col value">{data}</div>
      )
    }
    return toRender;
  }
}

export default ModelRunInputTarget;
