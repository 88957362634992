import { locality_data } from '../data/locality-data';

export default class LocalityData {
  constructor(locality) {
    this.locality = locality_data.find((element) => {
      return element.locality == locality
    });

    if(this.locality) {
      return true;
    } else {
      return false;
    }
  }

  defaultValuesForRisk() {
    return {
      detection: {
        qrisk: { currentProportion: this.locality.input_1, targetProportion: this.locality.input_1 },
        hypertension: { currentProportion: this.locality.input_2, targetProportion: this.locality.input_2 },
        familial_hypercholesterolaemia: { currentProportion: this.locality.input_3, targetProportion: this.locality.input_3 },
        atrial_fibrillation: { currentProportion: this.locality.input_4, targetProportion: this.locality.input_4 },
        diabetes: { currentProportion: this.locality.input_5, targetProportion: this.locality.input_5 },
        non_diabetic_hyperglycaemia: { currentProportion: this.locality.input_6, targetProportion: this.locality.input_6 },
        chronic_kidney_disease: { currentProportion: this.locality.input_7, targetProportion: this.locality.input_7 }
      },
      management: {
        qrisk: { currentProportion: this.locality.input_8, targetProportion: this.locality.input_8 },
        hypertension: { currentProportion: this.locality.input_9, targetProportion: this.locality.input_9 },
        familial_hypercholesterolaemia: { currentProportion: this.locality.input_10, targetProportion: this.locality.input_10 },
        atrial_fibrillation: { currentProportion: this.locality.input_11, targetProportion: this.locality.input_11 },
        diabetes: { currentProportion: this.locality.input_12, targetProportion: this.locality.input_12 },
        non_diabetic_hyperglycaemia: { currentProportion: this.locality.input_13, targetProportion: this.locality.input_13 },
        chronic_kidney_disease: { currentProportion: this.locality.input_14, targetProportion: this.locality.input_14 }
      },
      settings: {
        cost_discount_rate: "3.5",
        qalys_discount_rate: "1.5"
      }
    }
  }

  defaultValuesForIntervention() {
    return {
      intervention: {
        nhs_health_checks: { currentProportion: this.locality.input_15, targetProportion: this.locality.input_15 },
        annual_review: { currentProportion: this.locality.input_16, targetProportion: this.locality.input_16 },
        detection_of_fh_and_cascade_testing: { currentProportion: this.locality.input_17, targetProportion: this.locality.input_17 },
        lipid_modification_therapy: { currentProportion: this.locality.input_18, targetProportion: this.locality.input_18 },
        anti_hypertensive_therapy: { currentProportion: this.locality.input_19, targetProportion: this.locality.input_19 },
        anticoagulant_therapy: { currentProportion: this.locality.input_20, targetProportion: this.locality.input_20 },
        blood_glucose_lowering_medication: { currentProportion: this.locality.input_21, targetProportion: this.locality.input_21 },
        nhs_diabetes_prevention_programme: { currentProportion: this.locality.input_22, targetProportion: this.locality.input_22 },
        structured_diabetes_education: { currentProportion: this.locality.input_23, targetProportion: this.locality.input_23 },
        weight_management_programme: { currentProportion: this.locality.input_24, targetProportion: this.locality.input_24 },
        smoking_cessation_programmes: { currentProportion: this.locality.input_25, targetProportion: this.locality.input_25 },
        nutritional_advice_for_ckd: { currentProportion: this.locality.input_26, targetProportion: this.locality.input_26 },
        insulin_pump: { currentProportion: this.locality.input_27, targetProportion: this.locality.input_27 },
        pharmacist_new_medicine_review: { currentProportion: this.locality.input_28, targetProportion: this.locality.input_28 },
        blood_pressure_self_monitoring: { currentProportion: this.locality.input_29, targetProportion: this.locality.input_29 }
      },
      user_defined_intervention: {
        cvd_risk_factor: '0',
        cvd_relative_risk: '1',
        upfront_intervention_cost_per_person: '0',
        duration_of_effect: '0',
        current_eligible_proportion_undergoing_intervention: '0',
        target_eligible_proportion_undergoing_intervention: '0',
        subsequent_annual_intervention_cost_per_person: '0'
      },
      settings: {
        cost_discount_rate: "3.5",
        qalys_discount_rate: "1.5"
      }
    }
  }
}
