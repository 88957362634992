import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { LocalityForm, RiskFactorForm, InterventionForm } from 'forms/toolRun';
import Submit from 'components/Submit';

class Form extends Component {

  render() {
    let { page, type, onNextPage, onPrevPage, onSubmit, modelRunId, loading } = this.props;

    let toRender;

    if (page === 1) {
      toRender = <LocalityForm onSubmit={() => onNextPage(page)} />
    } else if (page === 2) {
      if (type === 'risk') {
        toRender = <RiskFactorForm previousPage={() => onPrevPage(page)} onSubmit={() => onSubmit(page)} loading={loading} />
      } else if (type === 'intervention') {
        toRender = <InterventionForm previousPage={() => onPrevPage(page)} onSubmit={() => onSubmit(page)} loading={loading} />
      }
    } else if (page === 3) {
      toRender = <Submit modelRunId={modelRunId} />
    }

    return (
      <div className='mt-4'>
        { toRender }
      </div>
    )
  }
}

Form.defaultProps = {
  page: 1,
  type: ''
};

export default Form;
