import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class SubHeaderRow extends Component {
  render() {
    const { value, colSpan } = this.props;

    return (
      <tr>
        <th colSpan={colSpan}>{value}</th>
      </tr>
    )
  }
}
