// Step 2a in the CVD Tool form
import React, { Component } from 'react';
import { FormSection, reduxForm, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import FormActions from 'components/form/FormActions';
import renderTextInput from 'components/form/RenderTextInput';
import renderToggleButton from 'components/form/RenderToggleButton';
import InputHelp from 'components/form/InputHelp';

import Settings from 'components/form/toolRun/Settings'
import ProportionInput from 'components/form/toolRun/ProportionInput';
import RiskFactorProportionHeader from 'components/form/toolRun/RiskFactorProportionHeader';

import {
  discount_settings_help,
  risk_factor_main_help,
  risk_detection_current_proportion_help,
  risk_detection_target_proportion_help,
  risk_management_current_proportion_help,
  risk_management_target_proportion_help,
  phased_help,
  prematureDeathHelp
} from 'data/form_help_text';
import { moveToPhased, moveToYear } from 'actions';
import LocalityData from 'services/localityData';

class RiskFactorForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      original_values: new LocalityData(this.props.locality).defaultValuesForRisk()
    };
  }

  handleKeyDown = function (e, cb) {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      cb();
    }
  };

  render() {
    const { handleSubmit, previousPage, phased, formValues, loading } = this.props;
    const { original_values } = this.state;

    return (
      <form onSubmit={handleSubmit} onKeyDown={(e) => {this.handleKeyDown(e, handleSubmit)}} id="riskFactorForm">
        <div className="proportion-forms">
          <h1 className="pt-2">I want to improve detection or management of key CVD risk factors <InputHelp text={risk_factor_main_help} /></h1>
          <div className="row align-items-center">
            <div className="col-8">
              <h2>I want to improve the detection of key CVD risk factors</h2>
            </div>
            <div className="col text-right">
              <InputHelp text={phased_help} />
              <Field
                name="phased"
                component={renderToggleButton}
                label="Changes phased in over time?"
                type="checkbox"
                className="phaseToggleButton"
                wrapClassName="d-inline-block"
                onToggleOn={() => this.props.moveToPhased(formValues)}
                onToggleOff={() => this.props.moveToYear(formValues)} />
            </div>
          </div>
          <FormSection name="detection" className="detection">
            <RiskFactorProportionHeader
              phased={phased}
              currentText="Current Proportion Detected out of Total Population Estimated to have the Risk Factor"
              targetText="Target Proportion Detected out of Total Population Estimated to have the Risk Factor"
              currentHelp={risk_detection_current_proportion_help}
              targetHelp={risk_detection_target_proportion_help} />
            <ProportionInput
              minimum_input={1.0}
              name="qrisk"
              label_name="QRISK ≥ 10%"
              phased={phased}
              originalValue={original_values.detection.qrisk.targetProportion}
              suggestedTarget={false} />
            <ProportionInput
              minimum_input={1.0}
              label_name="Hypertension"
              name="hypertension"
              phased={phased}
              originalValue={original_values.detection.hypertension.targetProportion}
              suggestedTarget={80.0} />
            <ProportionInput
              minimum_input={1.0}
              label_name="Familial Hypercholesterolaemia"
              name="familial_hypercholesterolaemia"
              phased={phased}
              originalValue={original_values.detection.familial_hypercholesterolaemia.targetProportion}
              suggestedTarget={false} />
            <ProportionInput
              minimum_input={1.0}
              label_name="Atrial Fibrillation"
              helpText={prematureDeathHelp}
              name="atrial_fibrillation"
              phased={phased}
              originalValue={original_values.detection.atrial_fibrillation.targetProportion}
              suggestedTarget={90.0} />
            <ProportionInput
              minimum_input={1.0}
              label_name="Diabetes"
              name="diabetes"
              phased={phased}
              originalValue={original_values.detection.diabetes.targetProportion}
              suggestedTarget={false} />
            <ProportionInput
              minimum_input={1.0}
              label_name="Non-diabetic hyperglycaemia"
              name="non_diabetic_hyperglycaemia"
              phased={phased}
              originalValue={original_values.detection.non_diabetic_hyperglycaemia.targetProportion}
              suggestedTarget={false} />
            <ProportionInput
              minimum_input={1.0}
              label_name="Chronic Kidney Disease"
              name="chronic_kidney_disease"
              phased={phased}
              originalValue={original_values.detection.chronic_kidney_disease.targetProportion}
              suggestedTarget={false} />
          </FormSection>

          <h2 className="pt-5">I want to improve management of key CVD risk factors</h2>
          <FormSection name="management" className="management">
            <RiskFactorProportionHeader
              phased={phased}
              currentText="Current Proportion Well-Managed out of Detected Population"
              targetText="Target Proportion Well-Managed out of Detected Population"
              currentHelp={risk_management_current_proportion_help}
              targetHelp={risk_management_target_proportion_help}
              suggestedTarget={false} />
            <ProportionInput
              minimum_input={1.0}
              label_name="QRISK ≥ 10%"
              name="qrisk"
              phased={phased}
              originalValue={original_values.management.qrisk.targetProportion}
              suggestedTarget={false} />
            <ProportionInput
              minimum_input={1.0}
              label_name="Hypertension"
              name="hypertension"
              phased={phased}
              originalValue={original_values.management.hypertension.targetProportion}
              suggestedTarget={80.0} />
            <ProportionInput
              minimum_input={1.0}
              label_name="Familial Hypercholesterolaemia"
              name="familial_hypercholesterolaemia"
              phased={phased}
              originalValue={original_values.management.familial_hypercholesterolaemia.targetProportion}
              suggestedTarget={false} />
            <ProportionInput
              minimum_input={1.0}
              label_name="Atrial Fibrillation"
              helpText={prematureDeathHelp}
              name="atrial_fibrillation"
              phased={phased}
              originalValue={original_values.management.atrial_fibrillation.targetProportion}
              suggestedTarget={90.0} />
            <ProportionInput
              minimum_input={1.0}
              label_name="Diabetes"
              name="diabetes"
              phased={phased}
              originalValue={original_values.management.diabetes.targetProportion}
              suggestedTarget={false} />
            <ProportionInput
              minimum_input={1.0}
              label_name="Non-diabetic hyperglycaemia"
              name="non_diabetic_hyperglycaemia"
              phased={phased}
              originalValue={original_values.management.non_diabetic_hyperglycaemia.targetProportion}
              suggestedTarget={false} />
            <ProportionInput
              minimum_input={1.0}
              label_name="Chronic Kidney Disease"
              name="chronic_kidney_disease"
              phased={phased}
              originalValue={original_values.management.chronic_kidney_disease.targetProportion}
              suggestedTarget={false} />
          </FormSection>
          <p className="text-muted mt-3 mb-1">*PHE CVD Ambitions by 2023</p>
        </div>
        <h2 className="d-flex align-items-center mr-auto">Settings <InputHelp text={discount_settings_help} className="ml-2" /></h2>
        <FormSection name="settings">
          <Settings />
        </FormSection>
        <FormActions onPreviousClick={previousPage} nextButtonText="Run Model" loading={loading} />
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    phased: state.form.toolRun.values.phased,
    locality: state.form.toolRun.values.locality.label,
    formValues: getFormValues('toolRun')(state)
  };
};

export const connectedRiskFactorForm = connect(mapStateToProps, { moveToPhased, moveToYear })(RiskFactorForm);

export default reduxForm({
  form: 'toolRun',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(connectedRiskFactorForm)
