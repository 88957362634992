import $ from 'jquery';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

class ScrollSpy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navOpen: false
    }
  }

  componentDidMount() {
    $('body').scrollspy({ target: '#scrollSpy', offset: 100 });
  }

  componentDidUpdate() {
    $('body').scrollspy('refresh');
  }

  toggleNav = () => {
    this.setState({
      navOpen: !this.state.navOpen
    })
  }

  render() {
    const { selected } = this.props;
    const { navOpen } = this.state;

    let nav = [
      {
        title: 'High CVD Risk in Total Population',
        id: 'table1'
      },{
        title: 'Key Model Results',
        id: 'table2'
      }
    ];

    let subGroupNavs = {
      high_cvd_risk: [],
      mortality_life_years: [],
      costs: [],
      cost_effectiveness: []
    }

    const subGroupTitles = {
      high_cvd_risk: 'Clinical Events Subgroups',
      mortality_life_years: 'Mortality/Life Years Subgroups',
      costs: 'Costs Subgroups',
      cost_effectiveness: 'Health Benefits'
    }

    if(selected.types && selected.types.clinical_events) {
      nav.push({
        title: 'Clinical Events Avoided',
        id: 'table3'
      });
      nav.push({
        title: 'Reduction in cumulative CVD events',
        id: 'figure1'
      });

      if(selected.sub_groups) {
        if(selected.sub_groups.high_cvd_risk) {
          subGroupNavs.high_cvd_risk.push({
            title: 'by High Risk Condition',
            id: 'figure6'
          });
        }

        if(selected.sub_groups.socioeconomic_status) {
          subGroupNavs.high_cvd_risk.push({
            title: 'by Socio-Economic Status',
            id: 'figure10'
          });
        }

        if(selected.sub_groups.ethnicity) {
          subGroupNavs.high_cvd_risk.push({
            title: 'by Ethnicity',
            id: 'figure14'
          });
        }

        if(selected.sub_groups.age_band) {
          subGroupNavs.high_cvd_risk.push({
            title: 'by Age Group',
            id: 'figure18'
          });
        }
      }
    }

    if(selected.types && selected.types.mortality_life_years) {
      nav.push({
        title: 'Life Years Gained',
        id: 'table4'
      });
      nav.push({
        title: 'Life years over time',
        id: 'figure2'
      });

      if(selected.sub_groups) {
        if(selected.sub_groups.high_cvd_risk) {
          subGroupNavs.mortality_life_years.push({
            title: 'by High Risk Condition',
            id: 'figure7'
          });
        }

        if(selected.sub_groups.socioeconomic_status) {
          subGroupNavs.mortality_life_years.push({
            title: 'by Socio-Economic Status',
            id: 'figure11'
          });
        }

        if(selected.sub_groups.ethnicity) {
          subGroupNavs.mortality_life_years.push({
            title: 'by Ethnicity',
            id: 'figure15'
          });
        }

        if(selected.sub_groups.age_band) {
          subGroupNavs.mortality_life_years.push({
            title: 'by Age Group',
            id: 'figure19'
          });
        }
      }
    }

    if(selected.types && selected.types.costs) {
      nav.push({
        title: 'Intervention Costs',
        id: 'table5'
      });
      nav.push({
        title: 'Intervention Costs over time',
        id: 'figure3'
      });
      nav.push({
        title: 'Primary/Secondary/Social Care savings',
        id: 'figure4'
      });
      nav.push({
        title: 'Cost savings by disease area',
        id: 'table6'
      });

      if(selected.sub_groups) {
        if(selected.sub_groups.high_cvd_risk) {
          subGroupNavs.costs.push({
            title: 'by High Risk Condition',
            id: 'figure8'
          });
        }

        if(selected.sub_groups.socioeconomic_status) {
          subGroupNavs.costs.push({
            title: 'by Socio-Economic Status',
            id: 'figure12'
          });
        }

        if(selected.sub_groups.ethnicity) {
          subGroupNavs.costs.push({
            title: 'by Ethnicity',
            id: 'figure16'
          });
        }

        if(selected.sub_groups.age_band) {
          subGroupNavs.costs.push({
            title: 'by Age Group',
            id: 'figure20'
          });
        }
      }
    }

    if(selected.types && selected.types.cost_effectiveness) {
      nav.push({
        title: 'Health Benefits',
        id: 'table7'
      });
      nav.push({
        title: 'Net monetary benefit over time',
        id: 'figure5'
      });

      if(selected.sub_groups) {
        if(selected.sub_groups.high_cvd_risk) {
          subGroupNavs.cost_effectiveness.push({
            title: 'by High Risk Condition',
            id: 'figure9'
          });
        }

        if(selected.sub_groups.socioeconomic_status) {
          subGroupNavs.cost_effectiveness.push({
            title: 'by Socio-Economic Status',
            id: 'figure13'
          });
        }

        if(selected.sub_groups.ethnicity) {
          subGroupNavs.cost_effectiveness.push({
            title: 'by Ethnicity',
            id: 'figure17'
          });
        }

        if(selected.sub_groups.age_band) {
          subGroupNavs.cost_effectiveness.push({
            title: 'by Age Group',
            id: 'figure21'
          });
        }
      }
    }

    const nav_link = (item) => (<a key={item.id} className="nav-link" href={`#${item.id}`}>{item.title}</a>)

    return (
      <div id="scrollSpy" className={`navbar ${ navOpen ? 'show' : 'hidden'}`}>
        <nav className="nav flex-column">
          { nav.map(item => nav_link(item))}
          { Object.keys(subGroupNavs).map(group => {
            if(subGroupNavs[group].length > 0) {
              return (
                <Fragment key={group}>
                  <a className="nav-link" href={`#${group}`}>{subGroupTitles[group]}</a>
                  <nav className="nav flex-column">
                    { subGroupNavs[group].map(item => nav_link(item) )}
                  </nav>
                </Fragment>
              )
            }
          }) }
        </nav>
        <div className="navOpenButton" onClick={this.toggleNav}>
          { navOpen ? (
            <FontAwesomeIcon icon='chevron-left' />
          ) : (
            <FontAwesomeIcon icon='chevron-right' />
          )}

        </div>
      </div>
    )
  }
}

ScrollSpy.defaultProps = {
  selected: {
    types: {
      clinical_events: false,
      mortality_life_years: false,
      costs: false,
      cost_effectiveness: false
    },
    sub_groups: {
      high_cvd_risk: false,
      socioeconomic_status: false,
      ethnicity: false,
      age_band: false
    }
  }
};


export default ScrollSpy
