import React, { Component } from 'react';
import Raven from "raven-js";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Raven.captureException(error, { extra: errorInfo });
  }

  render() {
    if (this.state.error) {
      return (
        <div className="container">
          <h1>We're sorry — something's gone wrong.</h1>
          <p>Our team has been notified.</p>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}