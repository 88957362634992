import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import SubHeaderRow from './SubHeaderRow';
import { groupBy } from '../../../services/figures/data_utils';

export default class Table extends Component {
  render() {
    const { tableData, headers, title, conditionalFormating, id, columnClassNames, rowClassNames, rowConditionals } = this.props

    const groupTableData = groupBy(tableData, row => row._row)

    const tableRows = Array.from(groupTableData.values()).map(item => {
      const {_row, ...values} = item[0];

      if (Object.keys(values).length === 0) {
        return (
          <SubHeaderRow
            key={_row}
            value={_row}
            colSpan={headers.length + 1}
          />
        )
      } else {
        return (
          <TableRow
            key={_row}
            name={_row}
            values={values}
            headers={headers}
            conditionalFormating={conditionalFormating}
            columnClassNames={columnClassNames}
            rowClassNames={rowClassNames}
          />
        )

      }
    })

    return(
      <div id={id}>
        <h3>{title}</h3>
        <table className="table table-striped datatable">
          <TableHeader headers={headers} />
          <tbody>{tableRows}</tbody>
        </table>
      </div>
    )
  }
}
