import React, { Component } from 'react';

import ModelRunInputHeader from './ModelRunInputHeader';
import ModelRunInputTarget from './ModelRunInputTarget';

import { user_defined_interventions } from 'data/intervention'

const findCvdRiskFactor = (selectedRiskFactor) => {
  // selectedRiskFactor can be one of the following
  // undefined or 0 should return 0 / none

  // undefined
  // "0"
  // { value: "1", label: "All Conditions" }

  var value = 0
  if (selectedRiskFactor !== undefined) {
    value = selectedRiskFactor.value || selectedRiskFactor
  }

  const foundIntervention = user_defined_interventions.find((intervention) => intervention.value == value);
  return foundIntervention ? foundIntervention.label : 'None';
}

class ModelRunInputsIntervention extends Component {
  render() {
    const { input_data } = this.props;

    return (
      <React.Fragment>
        <h5>I want to improve usage of key interventions for people at risk of CVD</h5>
        <div>
          <ModelRunInputHeader
            phased={input_data.phased}
            currentText = "Current Proportion eligible undergoing each intervention"
            targetText = "Target Proportion eligible undergoing each intervention" / >
          <div className="row inputRow">
            <div className="col-4 field">NHS Health Checks</div>
            <div className="col value">{input_data.intervention.nhs_health_checks.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.intervention.nhs_health_checks.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Annual Review</div>
            <div className="col value">{input_data.intervention.annual_review.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.intervention.annual_review.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Detection of FH and Cascade Testing</div>
            <div className="col value">{input_data.intervention.detection_of_fh_and_cascade_testing.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.intervention.detection_of_fh_and_cascade_testing.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Lipid Modification Therapy</div>
            <div className="col value">{input_data.intervention.lipid_modification_therapy.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.intervention.lipid_modification_therapy.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Anti-hypertensive Therapy</div>
            <div className="col value">{input_data.intervention.anti_hypertensive_therapy.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.intervention.anti_hypertensive_therapy.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Anticoagulant Therapy</div>
            <div className="col value">{input_data.intervention.anticoagulant_therapy.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.intervention.anticoagulant_therapy.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Blood Glucose Lowering Medication</div>
            <div className="col value">{input_data.intervention.blood_glucose_lowering_medication.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.intervention.blood_glucose_lowering_medication.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">NHS Diabetes Prevention Programme</div>
            <div className="col value">{input_data.intervention.nhs_diabetes_prevention_programme.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.intervention.nhs_diabetes_prevention_programme.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Structured Diabetes Education</div>
            <div className="col value">{input_data.intervention.structured_diabetes_education.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.intervention.structured_diabetes_education.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Weight Management Programmes</div>
            <div className="col value">{input_data.intervention.weight_management_programme.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.intervention.weight_management_programme.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Smoking Cessation Programmes</div>
            <div className="col value">{input_data.intervention.smoking_cessation_programmes.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.intervention.smoking_cessation_programmes.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Nutritional Advice for CKD</div>
            <div className="col value">{input_data.intervention.nutritional_advice_for_ckd.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.intervention.nutritional_advice_for_ckd.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Insulin Pump</div>
            <div className="col value">{input_data.intervention.insulin_pump.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.intervention.insulin_pump.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Pharmacist New Medicine Review</div>
            <div className="col value">{input_data.intervention.pharmacist_new_medicine_review.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.intervention.pharmacist_new_medicine_review.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Blood Pressure Self Monitoring</div>
            <div className="col value">{input_data.intervention.blood_pressure_self_monitoring.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.intervention.blood_pressure_self_monitoring.targetProportion} />
          </div>
        </div>
        <h5>User Defined Intervention</h5>
        <div>
          <div className="row inputRow">
            <div className="col-4 field">CVD Risk Factor</div>
            <div className="col value">{findCvdRiskFactor(input_data.user_defined_intervention.cvd_risk_factor)}</div>
          </div>
          <div className="row inputRow">
            <div className="col-4 field">CVD Relative Risk</div>
            <div className="col value">{input_data.user_defined_intervention.cvd_relative_risk}</div>
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Current Eligible Proportion Undergoing Intervention</div>
            <div className="col value">{input_data.user_defined_intervention.current_eligible_proportion_undergoing_intervention}</div>
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Target Eligible Proportion Undergoing Intervention</div>
            <div className="col value">{input_data.user_defined_intervention.target_eligible_proportion_undergoing_intervention}</div>
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Upfront Intervention Cost Per Person</div>
            <div className="col value">{input_data.user_defined_intervention.upfront_intervention_cost_per_person}</div>
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Subsequent Annual Intervention Cost Per Person</div>
            <div className="col value">{input_data.user_defined_intervention.subsequent_annual_intervention_cost_per_person}</div>
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Duration of effect (years)</div>
            <div className="col value">{input_data.user_defined_intervention.duration_of_effect}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ModelRunInputsIntervention;
