import React, { Component } from 'react';

import ModelRunInputHeader from './ModelRunInputHeader';
import ModelRunInputTarget from './ModelRunInputTarget';

class ModelRunInputsRiskFactor extends Component {

  render() {
    const { input_data } = this.props;

    return (
      <React.Fragment>
        <h5>I want to improve the detection of key CVD risk factors</h5>
        <div>
          <ModelRunInputHeader
            phased={input_data.phased}
            currentText="Current Proportion Detected out of Total Population Estimated to have the Risk Factor"
            targetText="Target Proportion Detected out of Total Population Estimated to have the Risk Factor" />
          <div className="row inputRow">
            <div className="col-4 field">QRISK ≥ 10%</div>
            <div className="col value">{input_data.detection.qrisk.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.detection.qrisk.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Hypertension</div>
            <div className="col value">{input_data.detection.hypertension.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.detection.hypertension.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Familial Hypercholesterolaemia</div>
            <div className="col value">{input_data.detection.familial_hypercholesterolaemia.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.detection.familial_hypercholesterolaemia.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Atrial Fibrillation</div>
            <div className="col value">{input_data.detection.atrial_fibrillation.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.detection.atrial_fibrillation.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Diabetes</div>
            <div className="col value">{input_data.detection.diabetes.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.detection.diabetes.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Non-diabetic hyperglycaemia</div>
            <div className="col value">{input_data.detection.non_diabetic_hyperglycaemia.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.detection.non_diabetic_hyperglycaemia.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Chronic Kidney Disease</div>
            <div className="col value">{input_data.detection.chronic_kidney_disease.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.detection.chronic_kidney_disease.targetProportion} />
          </div>
        </div>
        <h5>I want to improve management of key CVD risk factors</h5>
        <div>
          <ModelRunInputHeader
            phased={input_data.phased}
            currentText="Current Proportion Well-Managed out of Detected Population"
            targetText="Target Proportion Well-Managed out of Detected Population" />
          <div className="row inputRow">
            <div className="col-4 field">QRISK ≥ 10%</div>
            <div className="col value">{input_data.management.qrisk.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.management.qrisk.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Hypertension</div>
            <div className="col value">{input_data.management.hypertension.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.management.hypertension.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Familial Hypercholesterolaemia</div>
            <div className="col value">{input_data.management.familial_hypercholesterolaemia.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.management.familial_hypercholesterolaemia.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Atrial Fibrillation</div>
            <div className="col value">{input_data.management.atrial_fibrillation.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.management.atrial_fibrillation.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Diabetes</div>
            <div className="col value">{input_data.management.diabetes.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.management.diabetes.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Non-diabetic hyperglycaemia</div>
            <div className="col value">{input_data.management.non_diabetic_hyperglycaemia.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.management.non_diabetic_hyperglycaemia.targetProportion} />
          </div>
          <div className="row inputRow">
            <div className="col-4 field">Chronic Kidney Disease</div>
            <div className="col value">{input_data.management.chronic_kidney_disease.currentProportion}</div>
            <ModelRunInputTarget phased={input_data.phased} data={input_data.management.chronic_kidney_disease.targetProportion} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ModelRunInputsRiskFactor;
