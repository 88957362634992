/*
* Reduction in cumulative CVD events over time
* Chart 6, Chart 7, Chart 8, Chart 9
*/

import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';

import { groupBy, arrayOfNumbers, buildOptions, buildDataSet, formatThousands } from '../../../services/figures/data_utils';

export default class SocioEconomicStatus extends Component {
  render() {
    const { data, years, title, yAxisLabel } = this.props;

    const fixedData = groupBy(data, entry => entry.variable);

    let options = buildOptions(title, 'Year', yAxisLabel);
    options.scales.yAxes[0].ticks = {};
    options.scales.yAxes[0].ticks.callback = value => value.toLocaleString();
    options.layout = { padding: { right: 50 } }

    const iterator = fixedData.keys();

    return(
      <Line
        height={200}
        data={{
          labels: arrayOfNumbers(years),
          datasets: [
            buildDataSet(fixedData.get(iterator.next().value), 'IMD Q1', '#FF6600'),
            buildDataSet(fixedData.get(iterator.next().value), 'IMD Q2', '#33FF66'),
            buildDataSet(fixedData.get(iterator.next().value), 'IMD Q3', '#000099'),
            buildDataSet(fixedData.get(iterator.next().value), 'IMD Q4', '#999900'),
            buildDataSet(fixedData.get(iterator.next().value), 'IMD Q5', '#339999')
          ]
        }}
        options={options}
      />
    )
  }
}
