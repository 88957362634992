import React, { Component } from 'react';

const renderCustomCheckbox = ({className, input, label}) => (
  <div className={`custom-control custom-checkbox ${className}`}>
    <input {...input} id={input.name} className={`custom-control-input ${input.className}`} type="checkbox" /> 
    <label htmlFor={input.name} className="custom-control-label">
      {label}
    </label>
  </div>
)

export default renderCustomCheckbox;
