export const user_defined_interventions = [
  { value: '0', label: 'None'},
  { value: '1', label: 'All Conditions'},
  { value: '2', label: 'Hypertension'},
  { value: '3', label: 'QRISK >=10%'},
  { value: '4', label: 'Familial Hypercholesterolaemia'},
  { value: '5', label: 'Atrial Fibrillation'},
  { value: '6', label: 'Type 1 Diabetes'},
  { value: '7', label: 'Type 2 Diabetes'},
  { value: '8', label: 'Non-Diabetic Hyperglycaemia'},
  { value: '9', label: 'Chronic Kidney Disease'}]