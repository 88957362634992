import React, { Component } from 'react';
import { defaults } from 'react-chartjs-2';
defaults.global.animation = false;

require('bootstrap');

import Chart1 from 'components/figures/charts/Chart1';
import Chart2 from 'components/figures/charts/Chart2';
import Chart3 from 'components/figures/charts/Chart3';
import Chart4 from 'components/figures/charts/Chart4';
import Chart5 from 'components/figures/charts/Chart5';
import RiskCondition from 'components/figures/charts/RiskCondition';
import SocioEconomicStatus from 'components/figures/charts/SocioEconomicStatus';
import Ethnicity from 'components/figures/charts/Ethnicity';
import AgeGroup from 'components/figures/charts/AgeGroup';
import Table from 'components/figures/table/Table';

import ModelRunInputs from '../components/ModelRunInputs';

export class OutputPDF extends Component {
  createYearTextArray(years) {
    const yearArray = ['Year 1', 'Year 3', 'Year 5', 'Year 10', 'Year 15', 'Year 20'];

    const index = {
      1: 1,
      3: 2,
      5: 3,
      10: 4,
      15: 5,
      20: 6
    }

    return yearArray.splice(0, index[years.toString()]);
  }

  componentDidMount() {
    window.status = 'REACT_DONE';
  }

  render() {
    let { data, input_data } = this.props;

    let years = 20;

    const yearTableHeaders = this.createYearTextArray(years);

    return (
      <div>
        <h1>{input_data.run_name}</h1>
        <ModelRunInputs input_data={input_data} />

        <p>Results are presented first for the whole population in your chosen locality, and then if selected, for specific subgroups of the population.  Total results are presented for the whole population. Subgroup results are presented per 1,000 individuals in that subgroup at baseline/model start.</p>
        <p>All the results in this report are presented as the ‘cumulative incremental’ results of implementing your scenario. ‘Incremental’ means the difference between the current status quo and your chosen scenario for improved identification/management of risk factors or usage of interventions. When the model is run, the total outcomes (e.g. clinical events, costs/savings) are calculated for the scenario and for the current status quo, then the difference between these is calculated to obtain incremental events or incremental costs/savings. ‘Cumulative’ means that the incremental costs/savings/events in each year represent the sum of all incremental costs/savings/events over all previous years.</p>
        <Table
          title="Table 1: Estimated Prevalence of High CVD Risk Conditions amongst the Total Population at Baseline"
          tableData={data.Table_1}
          headers={
            ['Percentage of popn', 'Number', 'Definition']
          }
          id="table1"
          columnClassNames={{'Definition': 'defCol'}}
          highlight={false} />
        <p className='text-muted'>Numbers and estimated prevalence apply to the population aged 16+</p>
        <Table
          title="Table 2: Key Model Results"
          tableData={data.Table_2}
          headers={yearTableHeaders}
          id="table2"
          reversedDanger={true}
          />
        <p className='text-muted'>
          Premature deaths are defined as those before age 75. ‘Total Intervention Costs’ includes the costs of all interventions whose usage is increased as a result of increasing detection/management of a high risk condition, or usage of an intervention. ‘Total Cost Savings’ includes savings to the NHS and to social care that occur through prevention of CVD and other conditions. Overall (Net) Cost or Savings is calculated as ‘Total Cost Savings’ plus ‘Total Intervention Costs’.
        </p>

        <h1 className="mt-5 mb-2">Outputs</h1>
        <p>This section is made up of each of the main sections of outputs from the model.</p>
        <div className="outputSection">
          <h2>Clinical Events</h2>
          <Table
            title="Table 3: Clinical Events (cumulative)"
            tableData={data.Table_3}
            headers={yearTableHeaders}
            reversedDanger={true}
            id="table3" />
          <p className="text-muted">
            Table 3 shows the change in CVD events by event type. Reductions in events are shown as negative (below the x-axis). Any positive values indicate an increase – this can arise due to competing risks, e.g. a reduction in heart attacks would lead to increased survival, thereby increasing the number of individuals subsequently at risk of other types of event such as end stage renal disease.
          </p>
          <Chart1 data={data.Fig1data} years={years} id="figure1" />
        </div>

        {/* For selecting mortality/life years */}
        <div className="outputSection">
          <h2>Mortality/Life Years</h2>
          <Table
            title="Table 4: Life Years Gained & Premature Mortality Cases Avoided (cumulative)"
            tableData={data.Table_4}
            headers={yearTableHeaders}
            id="table4"
            rowConditionals={{ "Premature Mortality cases": { reversedDanger: true } }} />
          <p className='text-muted'>Premature deaths are defined as those before age 75. In table 4 above, reductions in Premature Mortality appear as negative.</p>
          <p className='text-muted'>Although highly effective, anticoagulants are also associated with a relatively small risk of major bleeding, which may be fatal. The magnitude of this effect is subject to considerable uncertainty, and the modelled effects on premature mortality when running scenarios that increase anticoagulant usage in this tool may not be statistically significant. Anticoagulants are recommended by NICE for their overall positive impacts on quality and length of life.</p>
          <Chart2 data={data.Fig2data} years={years} id="figure2" />
          <p className="text-muted">Premature deaths are defined as those before age 75</p>
          <p className='text-muted'>
            On the right hand y axis, increases in Life Years Gained show as positive values (above the x axis). Life Years Gained expresses the additional number of years of life that a person lives as a result of receiving an intervention. The numbers in Figure 2 represent the total life years gained by your population.
          </p>
        </div>

       {/* For selecting mortality/life years */}
        <div className="outputSection">
          <h2>Costs</h2>
          <Table
            title="Table 5: Intervention Costs, Savings and Overall Cost/Savings (cumulative)"
            conditionalFormating={{currency: true}}
            tableData={data.Table_5}
            headers={yearTableHeaders}
            id="table5"
            reversedDanger={true}
            rowClassNames={{'OVERALL COST OR SAVINGS, NET OF INTERVENTION (NHS AND SOCIAL CARE)': 'font-weight-bold'}} />
          <p className="text-muted">This tool has been designed to take account of the interactions between high risk factors and between different interventions aimed at detecting/managing those high risk factors. This means that your chosen scenario is likely to cause knock-on effects on the costs of other interventions that are not directly related to your chosen high risk condition/intervention. There are three main reasons for this:</p>
          <p className="text-muted">1) Increasing usage of medication interventions (or management of conditions that use medication) result in a higher eligibility for medicine use reviews and therefore will also increase the costs of medicine use review. A similar increase in the costs of blood pressure self-monitoring will be seen if you have implemented an increase in the usage of antihypertensives or the detection/management of hypertension. This costs will be implemented from year 1.</p>
          <p className="text-muted">2) If your chosen scenario has resulted in an increase in health of the population, this may mean that fewer people are now eligible for some of the interventions, thereby reducing the costs of these interventions. For example, if a scenario increases usage of the NHS DPP/management of people with non-diabetic hyperglycaemia, this will reduce diabetes and cardiovascular risk and result in fewer people requiring anti-diabetic treatments or statins. These costs will be seen from year 2 onwards.</p>
          <p className="text-muted">3) If your chosen scenario has resulted in a reduction in mortality in the population, this may mean that more people are surviving and hence now eligible for some of the interventions, thereby increasing the costs of these interventions. For example, if a scenario increases usage of antihypertensive treatments, this will result in people with both diabetes and hypertension surviving for longer and therefore an increase in the cost of anti-diabetic treatments. These costs will be seen from year 2 onwards.</p>
          <p className="text-muted">If the bottom "OVERALL COST OR SAVINGS" row is positive then the costs of intervention outweigh the resulting savings, whereas if the "OVERALL COST OR SAVINGS" row is negative then the savings outweigh the intervention costs.</p>
          <Chart3 data={data.Fig3data} years={years} id="figure3" />
          <p className="text-muted">
            Figure 3 shows the costs of intervention, the total savings due to reduced disease incidence, and the combined overall effect of these, i.e. net cost/savings. Cost savings are shown as negative values, an overall cost increase appears as a positive value.
          </p>
          <Chart4 data={data.Fig4data} years={years} id="figure4" />
          <p className="text-muted">
            The stacked line graph in Figure 4 shows simultaneously the total savings from a reduction in clinical events and the split with each care sector over time.
          </p>
          <Table
            title="Table 6: Cost Savings by disease area (cumulative)"
            conditionalFormating={{currency: true}}
            tableData={data.Table_6}
            headers={yearTableHeaders}
            reversedDanger={true}
            id="table6" />
        </div>

        {/* For selecting cost effectiveness */}
        <div className="outputSection">
          <h2>Health Benefits</h2>
          <Table
            title="Table 7: Health Benefits"
            tableData={data.Table_7}
            headers={yearTableHeaders}
            id="table7" />
          <p className="text-muted">
            The QALY is a measure of health that attempts to capture improvements to both quality and length of life. QALYs are calculated by multiplying the health-related quality of life value (using a single scale between one for perfect health and zero for death) with the length of life spent in that health state, for example, 10 years at 0.5 produces 5.0 QALYs.
          </p>
          <p className="text-muted">
            Net Monetary Benefit (NMB) is a calculation that takes both cost-savings and health benefits into account and converts them into a single monetised value. The calculation of NMB is based on 1 extra QALY having a monetary equivalent of £60,000, as per Department of Health guidelines.
          </p>
          <Chart5 data={data.Fig5data} years={years} id="figure5" />
        </div>

        <h1>Subgroups Results</h1>
        <p>All results for subgroups are per 1000 people at baseline/model start to enable comparison between different categories of each subgroups (e.g. individuals with hypertension compared to individuals with atrial fibrillation). In some cases, results may appear to be similar across such categories, which suggests that there is unlikely to be a significant difference between them.</p>
        <h2 id="high_cvd_risk">Clinical Events</h2>
        <div className="outputSection">
          <h3 id="figure6">High CVD Risk Conditions</h3>
          <RiskCondition
            data={data.Fig6data}
            title="Figure 6: Change in Cumulative CVD events over time by Risk Condition"
            yAxisLabel="Change in Number of CVD Events per 1000 people"
            years={years} />
        </div>

        <div className="outputSection">
          <h3 id="figure10">Socioeconomic Status</h3>
          <SocioEconomicStatus
            data={data.Fig10data}
            title={["Figure 7: Change in Cumulative CVD events", "over time by Socio-economic Group"]}
            yAxisLabel="Change in Number of CVD Events per 1000 people"
            years={years} />
          <p className="text-muted">
            IMD stands for Index of Multiple Deprivation. Individuals fall into one of five categories, IMD1 to IMD5. IMD 1 is the least deprived, IMD5 is the most deprived.
          </p>
        </div>

        <div className="outputSection">
          <h3 id="figure14">Ethnicity</h3>
          <Ethnicity
            data={data.Fig14data}
            title="Figure 8: Change in Cumulative CVD events over time by Ethnicity"
            yAxisLabel="Change in Number of CVD Events per 1000 people"
            years={years} />
        </div>

        <div id="figure18" className="outputSection">
          <h3>Age Group</h3>
          <AgeGroup
            data={data.Fig18data}
            title="Figure 9: Change in Cumulative CVD events over time by Age Group"
            yAxisLabel="Change in Number of CVD Events per 1000 people"
            years={years} />
        </div>

        <h2 id="mortality_life_years">Life Years Gained</h2>
        <div id="figure7" className="outputSection">
          <h3 >High CVD Risk Conditions</h3>
          <RiskCondition
            data={data.Fig7data}
            title="Figure 10: Life Years Gained over time by Risk Condition"
            yAxisLabel="Life Years Gained per 1000 people"
            years={years} />
        </div>

        <div id="figure11" className="outputSection">
          <h3>Socioeconomic Status</h3>
          <SocioEconomicStatus
            data={data.Fig11data}
            title="Figure 11: Life Years Gained over time by Socio-economic Group"
            yAxisLabel="Life Years Gained per 1000 people"
            years={years} />
          <p className="text-muted">
            IMD stands for Index of Multiple Deprivation. Individuals fall into one of five categories, IMD1 to IMD5. IMD 1 is the least deprived, IMD5 is the most deprived.
          </p>
        </div>

        <div id="figure15" className="outputSection">
          <h3>Ethnicity</h3>
          <Ethnicity
            data={data.Fig15data}
            title="Figure 12: Life Years Gained over time by Ethnicity"
            yAxisLabel="Life Years Gained per 1000 people"
            years={years} />
        </div>

        <div id="figure19" className="outputSection">
          <h3>Age Group</h3>
          <AgeGroup
            data={data.Fig19data}
            title="Figure 13: Life Years Gained over time by Age Group"
            yAxisLabel="Life Years Gained per 1000 people"
            years={years} />
        </div>

        <h2 id="costs">Costs</h2>
        <div id="figure8" className="outputSection">
          <h3>High CVD Risk Conditions</h3>
          <RiskCondition
            data={data.Fig8data}
            title={["Figure 14: Overall Cost or Savings,", "net of intervention cost, by Risk Condition"]}
            yAxisLabel="Cost (+ve) or Savings (-ve) per 1000 people"
            years={years} />
        </div>

        <div id="figure12" className="outputSection">
          <h3>Socioeconomic Status</h3>
          <SocioEconomicStatus
            data={data.Fig12data}
            title="Figure 15: Overall Cost or Savings over time by Socio-economic Group"
            yAxisLabel="Cost (+ve) or Savings (-ve) per 1000 people"
            years={years} />
          <p className="text-muted">
            IMD stands for Index of Multiple Deprivation. Individuals fall into one of five categories, IMD1 to IMD5. IMD 1 is the least deprived, IMD5 is the most deprived.
          </p>
        </div>

        <div className="outputSection">
          <h3 id="figure16">Ethnicity</h3>
          <Ethnicity
            data={data.Fig16data}
            title="Figure 16: Overall Cost or Savings over time by Ethnicity"
            yAxisLabel="Cost (+ve) or Savings (-ve) per 1000 people"
            years={years} />
        </div>

        <div id="figure20" className="outputSection">
          <h3>Age Group</h3>
          <AgeGroup
            data={data.Fig20data}
            title="Figure 17: Overall Cost or Savings over time by Age Group"
            yAxisLabel="Cost (+ve) or Savings (-ve) per 1000 people"
            years={years} />
        </div>

        <h2 id="cost_effectiveness">Health Benefits</h2>
        <div id="figure9" className="outputSection">
          <h3>High CVD Risk Conditions</h3>
          <RiskCondition
            data={data.Fig9data}
            title={["Figure 18: Net monetary benefit over time (cumulative) by Risk Condition over time"]}
            yAxisLabel="Net Monetary Benefit per 1000 people"
            years={years} />
        </div>

        <div id="figure13" className="outputSection">
          <h3>Socioeconomic Status</h3>
          <SocioEconomicStatus
            data={data.Fig13data}
            title={["Figure 19: Net monetary benefit over time (cumulative) by Socio-economic group over time"]}
            yAxisLabel="Net Monetary Benefit per 1000 people"
            years={years} />
          <p className="text-muted">
            IMD stands for Index of Multiple Deprivation. Individuals fall into one of five categories, IMD1 to IMD5. IMD 1 is the least deprived, IMD5 is the most deprived.
          </p>
        </div>

        <div id="figure17" className="outputSection">
          <h3>Ethnicity</h3>
          <Ethnicity
            data={data.Fig17data}
            title={["Figure 20: Net monetary benefit over time (cumulative) by Ethnicity over time"]}
            yAxisLabel="Net Monetary Benefit per 1000 people"
            years={years} />
        </div>

        <div id="figure21" className="outputSection">
          <h3>Age Group</h3>
          <AgeGroup
            data={data.Fig21data}
            title={["Figure 21: Net monetary benefit over time (cumulative) by Age Group over time"]}
            yAxisLabel="Net Monetary Benefit per 1000 people"
            years={years} />
        </div>
      </div>
    )
  }
}

export default OutputPDF
