import React, { Component } from 'react'
import Select from 'react-select';
import InputHelp from 'components/form/InputHelp';

const renderSelectInput = ({ input, label, data, help_text, modalClassName, hint, meta: { touched, error } }) => (
  <div className="form-group">
    {help_text && <InputHelp text={help_text} modalClassName={modalClassName} className="float-right" />}
    <label>{label}</label>
    <Select
      {...input}
      className={`form-control ${input.name} ${touched && error ? 'is-invalid' : ''} `}
      options={data}
      onBlur={() => input.onBlur(input.value)}
      />
      {touched && error && <div className="invalid-feedback">{error}</div>}
      {hint && <p className="text-muted">{hint}</p>}
  </div>
)

export default renderSelectInput;
