// Output Selection

import React, { Component } from 'react';
import { Field, FormSection, reduxForm } from 'redux-form';
import { Collapse } from 'reactstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/fontawesome-free-solid';

import renderCustomCheckbox from 'components/form/RenderCustomCheckbox';
import renderRangeSlider from 'components/form/RenderRangeSlider';

import { } from 'data/form_help_text';

class OutputForm extends Component  {
  constructor(props) {
    super(props);

    this.state = { collapse: true }
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse })
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <div id="outputFilters">
        <form onSubmit={handleSubmit} id="outputForm" className="container">
          <h2 className="mb-0 mt-0" onClick={this.toggle}>Output filters { this.state.collapse ? <FontAwesomeIcon icon={fa.faChevronUp} /> : <FontAwesomeIcon icon={fa.faChevronDown} /> }</h2>
          <Collapse isOpen={this.state.collapse}>
            <div className="row">
              <div className="col-12">
                <p className="mt-3 mb-0">Filters allow users to select which types of outcome, e.g. Clinical events, they wish to be displayed. All results are displayed for the total population. Users may also view results for up to 4 sets of subgroups.</p>
                <p className = "mt-3 mb-0"> Please also select at least one type of output to see subgroup analyses. </p>
              </div>
              <FormSection name="types" className="col-md">
                <h3>Type of output</h3>
                <Field
                  name="clinical_events"
                  type="checkbox"
                  component={renderCustomCheckbox}
                  label="Clinical Events"
                />
                <Field
                  name="mortality_life_years"
                  type="checkbox"
                  component={renderCustomCheckbox}
                  label="Mortality/Life Years"
                />
                <Field
                  name="costs"
                  type="checkbox"
                  component={renderCustomCheckbox}
                  label="Costs"
                />
                <Field
                  name="cost_effectiveness"
                  type="checkbox"
                  component={renderCustomCheckbox}
                  label="Health Benefits"
                />
              </FormSection>
              <FormSection name="sub_groups" className="col-md">
                <h3>Plus subgroups analyses by:</h3>
                <Field
                  name="high_cvd_risk"
                  type="checkbox"
                  component={renderCustomCheckbox}
                  label="High CVD Risk Conditions"
                />
                <Field
                  name="socioeconomic_status"
                  type="checkbox"
                  component={renderCustomCheckbox}
                  label="Socioeconomic status (IMD quintiles)"
                />
                <Field
                  name="ethnicity"
                  type="checkbox"
                  component={renderCustomCheckbox}
                  label="Ethnicity (White, South Asian, Other)"
                />
                <Field
                  name="age_band"
                  type="checkbox"
                  component={renderCustomCheckbox}
                  label="Age Band (<40, 40-74, >=74)"
                />
              </FormSection>
              <div className="col-12 timeHorizon">
                <Field
                  label="Time Horizon"
                  name="years"
                  type="number"
                  component={renderRangeSlider}
                  min={1}
                  max={20}
                  defaultValue={5}
                  step={null}
                  marks={{
                    1: '1 Year',
                    3: '3 Years',
                    5: '5 Years',
                    10: '10 Years',
                    15: '15 Years',
                    20: '20 Years'
                  }}
                />
              </div>
              <div className="col-12">
                <p className="mt-0 mb-1 text-muted">Please click on the year for the desired time horizon, or drag the current selected circle to the desired time horizon.</p>
              </div>
            </div>
          </Collapse>
        </form>
      </div>
    )
  }
}

export default reduxForm({
  form: 'output'
})(OutputForm)
