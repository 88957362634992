import React, { Component } from 'react';
import { connect } from 'react-redux';

import Alert from 'components/Alert';
import { removeAlert } from 'actions/alert.actions'

class Alerts extends Component {
  render() {
    const alerts = this.props.alerts.map((alert) => <Alert key={alert.id} type={alert.type} text={alert.text} id={alert.id} onDismiss={this.props.removeAlert} />)

    return(
      <div className="alert-container">
        {alerts}
      </div>
    )
  }
}

function mapStateToProps({ alerts }) {
  return {
    alerts
  }
}

export default connect(mapStateToProps, { removeAlert })(Alerts)
