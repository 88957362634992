import React, { Component } from 'react';
import 'regenerator-runtime/runtime';

import OutputPDF from './OutputPDF';

class PDFPage extends Component {
  render() {
    const { data, input_data } = this.props;

    return (
      <OutputPDF data={data} input_data={input_data} />
    )
  }
}

export default PDFPage;
